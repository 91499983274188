import { colors } from '../colors'

export const customLinkStyle = {
	marginLeft: '5px',
	fontWeight: '600',
	fontSize: '16px',
	lineHeight: '100%',
	textDecoration: 'none',
	color: colors.blue,
}

export const passwordFormLinkStyle = {
	cursor: 'pointer',
	textDecoration: 'none',
	fontWeight: 600,
	fontSize: '14px',
	lineHeight: '110%',
	color: colors.blue,
	'&:hover': {
		textDecoration: 'none',
	},
}

export const navigationLinkStyle = {
	textDecoration: 'none',
	fontFamily: "'Nunito', sans-serif",
	fontWeight: 800,
	fontSize: '20px',
	lineHeight: '100%',
	color: colors['main-dark'],
}

export const dropDownLinkStyle = {
	margin: '10px 0 0',
	padding: '10px 28px',
	display: 'block',
	background: 'transparent',
	textDecoration: 'none',
	transition: 'background 0.35s',
	fontWeight: 600,
	fontSize: '15px',
	lineHeight: '100%',
	color: '#3c3c3c',
	'&:hover': {
		background: '#f4f4f4',
	},
}
