import { Tooltip } from '@chakra-ui/react'
import React from 'react'

import './styles.scss'

const Button = ({
	children,
	theme,
	type = 'button',
	height,
	className,
	disabledTooltipLabel,
	...props
}) => {
	if (props.disabled && disabledTooltipLabel) {
		return (
			<Tooltip
				placement='top-start'
				backgroundColor='#484964'
				fontSize={12}
				fontFamily='Nunito Sans'
				color='white'
				borderRadius={3}
				padding='9px 12px'
				label={disabledTooltipLabel}
			>
				<div>
					<button
						className={`button ${theme ? `button--${theme}` : ''} ${
							className || ''
						}`}
						type={type}
						style={{ height }}
						{...props}
					>
						{children}
					</button>
				</div>
			</Tooltip>
		)
	}

	return (
		<button
			className={`button ${theme ? `button--${theme}` : ''} ${className || ''}`}
			type={type}
			style={{ height }}
			{...props}
		>
			{children}
		</button>
	)
}

export default Button
