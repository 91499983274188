import * as R from 'ramda'

import { AnalyticsEventType, AnalyticsParams } from 'model/analytics'
import { ampEvent, ampEventWithEventProperty } from 'utils/amplitude'
import {
	createBookThunk,
	deleteBookThunk,
	fetchAllBooksThunk,
	fetchModerationBooksThunk,
	fetchUserBooksThunk,
	updateBookCoverThunk,
	updateBookFullScreenCoverThunk,
	updateBookStatusThunk,
	updateBookThunk,
} from './thunks'

import FacebookPixel from 'react-facebook-pixel'
import LinkedInTag from 'react-linkedin-insight'
import { REACT_APP_LINKEDIN_STORY_CREATED_CONVERSION_ID } from 'constants/user'
import { createSlice } from '@reduxjs/toolkit'
import { logoutUserThunk } from '../user/asyncThunks'

export const STATE_KEY = 'books'

const initialState = {}

export const booksSlice = createSlice({
	name: 'books',
	initialState,
	reducers: {
		deleteBookById: (state, action) => {
			state = state.filter(book => book.book_id !== action.payload)

			return state
		},
	},
	extraReducers: {
		[fetchUserBooksThunk.fulfilled]: (_, action) => {
			return R.indexBy(R.prop('book_id'), action.payload)
		},
		[createBookThunk.fulfilled]: (state, action) => {
			ampEvent(AnalyticsEventType.STORY_CREATED)
			const { book_id, ...book } = action.payload
			state[book_id] = book
			FacebookPixel.track('Lead')
			LinkedInTag.track(REACT_APP_LINKEDIN_STORY_CREATED_CONVERSION_ID)
		},
		[deleteBookThunk.fulfilled]: (state, action) => {
			const { book_id, source } = action.payload

			ampEventWithEventProperty(
				AnalyticsEventType.DELETE_STORY_SUCCESS,
				AnalyticsParams.deleteStorySuccess(source, book_id),
			)

			state[book_id].deleted = true
		},
		[deleteBookThunk.rejected]: (state, action) => {
			const { source, book_id } = action.meta.arg

			ampEventWithEventProperty(
				AnalyticsEventType.DELETE_STORY_FAIL,
				AnalyticsParams.deleteStoryFail(source, book_id),
			)

			state[book_id].deleted = true
		},
		[updateBookCoverThunk.pending]: (state, action) => {
			const { bookCover, book_id } = action.meta.arg
			state[book_id].cover_link = bookCover
		},
		[updateBookCoverThunk.fulfilled]: (state, action) => {
			const { link, book_id } = action.payload
			ampEvent(AnalyticsEventType.STORY_PICTURE_UPLOAD_SUCCESS)
			state[book_id].cover_link = link
		},
		[updateBookCoverThunk.rejected]: (_, action) => {
			ampEventWithEventProperty(
				AnalyticsEventType.STORY_PICTURE_UPLOAD_ERROR,
				AnalyticsParams.storyPictureUploadError(action.error.message),
			)
		},
		[updateBookThunk.fulfilled]: (state, action) => {
			ampEvent(AnalyticsEventType.STORY_UPDATED)

			const { book_id, ...bookInfo } = action.payload
			const oldBook = R.pathOr({}, [book_id], state)
			const updatedBook = { ...oldBook, ...R.dissoc('access_token', bookInfo) }
			state[book_id] = updatedBook
		},
		[updateBookThunk.rejected]: (_, action) => {
			ampEventWithEventProperty(
				AnalyticsEventType.STORY_UPDATE_ERROR,
				AnalyticsParams.storyUpdateError(action.error.message),
			)
		},
		[fetchAllBooksThunk.fulfilled]: (_, action) => {
			return R.indexBy(R.prop('book_id'), action.payload)
		},
		[fetchModerationBooksThunk.fulfilled]: (_, action) => {
			return R.indexBy(R.prop('book_id'), action.payload)
		},
		[updateBookStatusThunk.fulfilled]: (state, action) => {
			const { status, book_id } = action.payload

			state[book_id].status = status
		},
		[updateBookFullScreenCoverThunk.fulfilled]: (state, action) => {
			const { link, book_id } = action.payload
			state[book_id].full_screen_link = link
		},
		[logoutUserThunk.fulfilled]: () => {
			return initialState
		},
	},
})

export const { deleteBookById } = booksSlice.actions

export const booksReducer = booksSlice.reducer
