import { colors } from '../colors'

export const buttonStyles = {
	baseStyle: {
		fontFamily: 'Nunito Sans',
		fontStyle: 'normal',
		fontWeight: 700,
		borderRadius: '30px',
		fontSize: '14px',
		color: '#fff',
		lineHeight: '100%',
		_disabled: {
			opacity: 0.7,
			cursor: 'not-allowed',
		},
		_focus: {
			boxShadow: 'none',
		},
	},
	sizes: {
		extraS: {
			padding: '0',
			fontWeight: 'bold',
			fontSize: '15px',
			lineHeight: '22px',
			textAlign: 'center',
			letterSpacing: '-0.408px',
		},
		xs: {
			padding: '9px 17.5px',
		},
		sm: {
			padding: '10.5px 27.5px',
		},
		md: {
			padding: '15.5px 44px',
			minHeight: 50,
		},
		lg: {
			padding: '24px 93px',
		},
	},
	variants: {
		primary: {
			bg: '#D0006E',
			_hover: {
				bg: '#c3086c;',
			},
		},
		pink: {
			bg: 'rgba(208, 0, 110, 0.1)',
			border: '1px solid #d9d8d8',
			color: '#D0006E',
			_hover: {
				border: '1px solid #b7b9bc',
			},
		},
		white: {
			bg: '#fff',
			color: ' #0B080F',
			border: '1px solid #d9d8d8',
			_hover: {
				border: '1px solid #b7b9bc',
			},
		},
		select: {
			bg: '#fff',
			color: ' #0B080F',
			p: '10.5px 21px',
			border: '1px solid #d9d8d8',
		},
		yellow: {
			bg: '#FEF151',
		},
		dark: {
			color: colors['main-dark'],
			backgroundColor: 'transparent',
		},
	},
	defaultProps: {
		size: 'md',
		variant: 'primary',
	},
}

export const googleButton = {
	width: '100%',
	height: '61px',
	border: '1px solid #d9d8d8',
	borderRadius: '30px',
	backgroundColor: '#fff',
	fontWeight: 'bold',
	fontSize: '16px',
	lineHeight: '100%',
	color: '#323232',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: '18px',
	':hover': {
		backgroundColor: '#fff',
	},
}
