import HttpStatus from 'constants/http'
import { create } from 'axios'
import { logoutUserThunk } from 'redux/slices/user/asyncThunks'
import { store } from 'redux/store'
import { API_SERVER_URL } from 'shared/get-env'

const axiosApiInstance = create({
	baseURL: API_SERVER_URL,
})

axiosApiInstance.interceptors.request.use(
	async config => {
		const { access_token } = store.getState().user
		config.headers = {
			Authorization: access_token,
			'Content-Type': 'application/json',
		}
		return config
	},
	error => {
		Promise.reject(error)
	},
)

axiosApiInstance.interceptors.response.use(
	response => {
		return response
	},
	async function (error) {
		const originalRequest = error.config
		if (
			error.response.status === HttpStatus.NOT_AUTHORIZED &&
			!originalRequest._retry
		) {
			originalRequest._retry = true
			store.dispatch(logoutUserThunk())

			return axiosApiInstance(originalRequest)
		}
		return Promise.reject(error)
	},
)

export default axiosApiInstance
