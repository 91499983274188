import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const STATE_KEY = 'revenue'

const initialState = {
	reports_years: [moment().year()],
	selected_reports_year: moment().year(),
}

console.log(initialState)

export const revenueSlice = createSlice({
	name: 'revenue',
	initialState,
	reducers: {
		setReportYears: (state, action) => {
			console.log(action.payload)
			state.reports_years = action.payload
		},
		setSelectedReportsYear: (state, action) => {
			state.selected_reports_year = action.payload
		},
	},
	extraReducers: {},
})

export const { setReportYears, setSelectedReportsYear } = revenueSlice.actions

export const revenueReducer = revenueSlice.reducer
