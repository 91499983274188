export const ReviewChapterStatuses = Object.freeze({
	APPROVED: {
		displayName: 'Published',
		value: 'APPROVED',
	},
	DRAFT: {
		displayName: 'Draft',
		value: 'DRAFT',
	},
	ON_MODERATION: {
		displayName: 'In review',
		value: 'ON_MODERATION',
	},
	IN_MODERATION: {
		displayName: 'In review',
		value: 'IN_MODERATION',
	},
	TO_MODERATION: {
		displayName: 'Waiting for review',
		value: 'TO_MODERATION',
	},
	REJECTED: {
		displayName: 'Rejected',
		value: 'REJECTED',
	},
	REMOVED: {
		displayName: 'Removed',
		value: 'REMOVED',
	},
})

export const AlertStates = Object.freeze({
	ERROR: 'error',
	SUCCESS: 'success',
})

export const AlertMessages = Object.freeze({
	SUCCESS_DRAFT: 'Successfuly saved to draft!',
	SUCCESS_PUBLISHED: 'Successfuly publish!',
	SUCCESS_TO_MODERATED: 'Successfuly sent to moderation!',
	ERROR_DRAFT: 'Error with saving to draft!',
	ERROR_PUBLISHED: 'Error with publishing!',
	ERROR_TO_MODERATED: 'Error with sending to moderation!',
})

export const chapterTagsExampleLink =
	'https://docs.google.com/document/d/1EvQ0jlp4CROEzw1NF0XD3VwyzouV1HClS5mNhD7qBxk/edit'
