import { webKitStyle } from './input'

export const selectStyles = {
	baseStyle: {},
	variants: {
		default: {
			icon: {
				right: '-4px',
				fontSize: '21px',
			},
			field: {
				p: '0 0 4px 4px',
				h: '26.3px',
				width: '100%',
				fontSize: '14px',
				lineHeight: '110%',
				borderRadius: 'none',
				color: '#0b080f',
				borderBottom: '1px solid #dbdbdb',
				_hover: {
					outline: 'none',
					borderBottom: '1px solid var(--color--blue)',
				},
				_autofill: webKitStyle,
				_focus: {
					outline: 'none',
					borderColor: '#644ded',
					bg: '#FFFFFF',
				},
			},
		},
	},
	defaultProps: {
		variant: 'default',
	},
}
