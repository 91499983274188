import { colors } from '../colors'

export const customFormStyle = {
	position: 'relative',
	padding: '65px 63px 19px',
	width: '100%',
	background: '#fff',
	boxShadow: '0 0 7px rgba(180, 182, 192, 0.19)',
	borderRadius: '5px',
}

export const userDetailsFormStyle = {
	padding: '35px 200px 36px',
	flex: 1,
	background: colors['main-gray'],
}

export const createBookFormStyle = {
	display: 'grid',
	gridTemplateRows: '92px 1fr',
	height: '100%',
	border: '1px solid #e4e4e4',
	boxSizing: 'border-box',
	borderRadius: '5px',
	background: colors.white,
}
