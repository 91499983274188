import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from 'react-router-dom'
import React, { Suspense, useEffect } from 'react'
import { ampEventFront, ampSetUserProperty } from './utils/amplitude'
import { useDispatch, useSelector } from 'react-redux'

import Authenticate from './pages/Authenticate'
import AuthorizationPage from './pages/Authorization'
import FacebookPixel from 'react-facebook-pixel'
import LinkedInTag from 'react-linkedin-insight'
import LoadingSuspenseSpinner from './components/UI/LoadingSuspenseSpinner'
import ModeratorRoutes from './routes/Moderator'
import PDFViewer from './components/PDFViever'
import WriterRoutes from './routes/Writer'
import { getRedirectRouteByRole } from 'redux/slices/user/selectors'
import { setFbABTest } from './redux/slices/user'
import useQuery from './hooks/useQuery'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import RevenueRoutes from 'routes/Revenue'
import AdminRoutes from 'routes/Admin'
import VerificationPage from 'pages/VerificationPage'

// const WritersBenefits = lazy(() => import('pages/WritersBenefits'))

function App() {
	const dispatch = useDispatch()
	const { pathname } = useLocation()
	const { fb } = useQuery()
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	const redirectRoute = useSelector(getRedirectRouteByRole)

	useEffect(() => {
		LinkedInTag.init(process.env.REACT_APP_LINKEDIN_PARTNER_ID, 'px', false)
		ampSetUserProperty('user_id', window.user_id)
		window.history.scrollRestoration = 'manual'
		FacebookPixel.init(process.env.REACT_APP_PIXEL_ID)
		FacebookPixel.pageView()
		ampEventFront('page_view')
	}, [])

	useEffect(() => {
		if (fb === 'true') {
			dispatch(setFbABTest(true))
			navigate(pathname, { replace: true })
		}
	}, [dispatch, fb, navigate, pathname])

	return (
		<Suspense fallback={<LoadingSuspenseSpinner />}>
			<Routes>
				{/* <Route path='writers_benefits' element={<WritersBenefits />} /> */}
				<Route path='/auth/:authState' element={<AuthorizationPage />} />
				<Route path='/authenticate' element={<Authenticate />} />
				<Route
					path='/email/verification/:token'
					element={<VerificationPage />}
				/>
				<Route path='info'>
					<Route path='terms' element={<PDFViewer url='/pdf/terms.pdf' />} />
					<Route
						path='privacy_policy'
						element={<PDFViewer url='/pdf/privacy_policy.pdf' />}
					/>
					<Route
						path='guidelines'
						element={<PDFViewer url='/pdf/guidelines.pdf' />}
					/>
				</Route>
				<Route path='writer/*' element={<WriterRoutes />} />
				<Route path='moderator/*' element={<ModeratorRoutes />} />
				<Route path='*' element={<Navigate to={redirectRoute} />} />
				<Route path='revenue/*' element={<RevenueRoutes />} />
				<Route path='admin/*' element={<AdminRoutes />} />
			</Routes>
			<ToastContainer autoClose={5000} />
		</Suspense>
	)
}

export default App
