import { Button, Image } from '@chakra-ui/react'
import React, { memo } from 'react'
import { googleButton } from 'shared/utils/chakra-theme/components/button'

const GoogleButton = ({ onClick }) => (
	<Button type='button' sx={googleButton} onClick={onClick}>
		<Image
			src='/images/authorization/google-logo.svg'
			alt='google-logo-login'
			mr='13px'
		/>
		Continue with Google
	</Button>
)

export default memo(GoogleButton)
