import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Link } from '@chakra-ui/react'

const SignUpTermsAndPrivacy = () => {
	const { t } = useTranslation()

	return (
		<Flex
			direction='column'
			align='center'
			color='5d5e60'
			fontSize='13px'
			fontWeight='600'
			lineHeight='22px'
			m='3px 0 0'
		>
			<Box>
				{t('By signing up, I agree that I have read and accepted the')}{' '}
			</Box>
			<Box>
				<Link
					href='/info/terms'
					target='_blank'
					rel='noreferrer'
					color='#0052dd'
					_hover={{ textDecoration: 'none' }}
				>
					{t('Terms of Use')}
				</Link>{' '}
				{t('and')}{' '}
				<Link
					href='/info/privacy_policy'
					target='_blank'
					rel='noreferrer'
					color='#0052dd'
					_hover={{ textDecoration: 'none' }}
				>
					{t('Privacy Policy')}
				</Link>
			</Box>
		</Flex>
	)
}

export default SignUpTermsAndPrivacy
