export const headingStyle = {
	baseStyle: {
		fontWeight: 800,
	},
	sizes: {
		xxs: {
			fontWeight: 800,
			fontSize: '25px',
			lineHeight: '22px',
			letterSpacing: '-0.408px',
			fontFamily: `'Nunito Sans', sans-serif`,
		},
		xs: {
			fontSize: '39px',
			lineHeight: '22px',
			letterSpacing: '-0.408px',
			fontFamily: `'Nunito Sans', sans-serif`,
		},
		xm: {
			fontWeight: 800,
			fontSize: '32px',
			lineHeight: '44px',
			letterSpacing: '-0.01em',
			fontFamily: `'Nunito Sans', sans-serif`,
		},
		m: {
			fontWeight: 800,
			fontSize: '40px',
			lineHeight: '55px',
			letterSpacing: '-0.408px',
			fontFamily: `'Nunito Sans', sans-serif`,
		},
		ms: {
			fontWeight: 800,
			fontSize: '28px',
			lineHeight: '36px',
			letterSpacing: '-0.01em',
			fontFamily: `'Nunito Sans', sans-serif`,
		},
		book: {
			fontFamily: `'Nunito Sans', sans-serif`,
			mb: '28px',
			maxWidth: '45vw',
			fontWeight: 800,
			fontSize: '28px',
			lineHeight: '22px',
			letterSpacing: '-0.408px',
			overflowWrap: 'break-word',
		},
		s: {
			fontSize: '50px',
			fontFamily: `'Nunito', sans-serif`,
			lineHeight: '100%',
		},
		modal: {
			fontWeight: 800,
			fontSize: '28px',
			fontFamily: `'Nunito Sans', sans-serif`,
		},
	},
	variants: {
		white: {
			color: '#f4f4f4',
		},
		dark: {
			color: '#0b080f',
		},
	},
	defaultProps: {
		size: 's',
		variant: 'white',
	},
}
