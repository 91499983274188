import * as Yup from 'yup'

import { AnalyticsEventType, AnalyticsParams } from 'model/analytics'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ampEvent, ampEventWithEventProperty } from 'utils/amplitude'
import {
	// createUserThunk,
	getUserInfoThunk,
	loginUserThunk,
} from 'redux/slices/user/asyncThunks'
import {
	getFbABTest,
	getIsCommonUser,
	getIsUserModerator,
} from 'redux/slices/user/selectors'
import { useDispatch, useSelector } from 'react-redux'

import { AmpArgsByAuthState } from './models'
import AuthForm from './components/AuthForm'
import { getIsShowLoading } from 'redux/slices/ui/selectors'
import hashPassword from 'utils/hashPassword'
import { loginUser } from 'api/user'
import { setLoginProvider } from 'redux/slices/user'
import { PLATFORM_NAME } from 'constants/common'
import { Box, Heading, Text, Flex, Grid } from '@chakra-ui/react'
import WelcomeNewUserWindow from './components/WelcomeNewUserWindow'
import { errorToast } from 'components/DefaultToast'
import { useMutation } from '@apollo/client'
import { CREATE_USER } from 'entites/user/create-user.graphql'

const AuthorizationPage = () => {
	const { authState } = useParams()
	const { state } = useLocation()
	const dispatch = useDispatch()

	const fbABTest = useSelector(getFbABTest)
	const isShowLoading = useSelector(getIsShowLoading)
	const isUserModerator = useSelector(getIsUserModerator)
	const isUserAdminOrWriter = useSelector(getIsCommonUser)

	const [loginEmail, setLoginEmail] = useState('')
	const [isValidatedEmail, setIsValidatedEmail] = useState(false)
	const [isNewUser, setIsNewUser] = useState(false)

	const [createUser] = useMutation(CREATE_USER, {})

	const signInAnotherAccountHandler = useCallback(() => {
		setIsValidatedEmail(false)
		ampEvent(AnalyticsEventType.SIGN_IN_DIFFERENT_ACC_ATTEMPT)
	}, [setIsValidatedEmail])

	const handleResetPassword = useCallback(() => {
		ampEvent(AnalyticsEventType.PASSWORD_RESET_ATTEMPTED)
	}, [])

	const onSubmitLoginEmail = useCallback(
		({ email }) => {
			setLoginEmail(email)
			setIsValidatedEmail(true)
		},
		[setLoginEmail, setIsValidatedEmail],
	)

	const onSubmitLoginPassword = useCallback(
		async ({ password }) => {
			const hashedPassword = await hashPassword(password)

			await dispatch(
				loginUserThunk({ email: loginEmail, password: hashedPassword }),
			)
			await dispatch(getUserInfoThunk())
		},
		[dispatch, loginEmail],
	)

	const loginButtonHandler = useCallback(() => {
		ampEventWithEventProperty(
			AnalyticsEventType.SIGN_IN_ATTEMPTED,
			AnalyticsParams.signInAttempted('email'),
		)
	}, [])

	const signUpLinkHandler = useCallback(() => {
		ampEventWithEventProperty(
			AnalyticsEventType.ACCOUNT_CREATION_STARTED,
			AnalyticsParams.accountCreationStarted('email'),
		)
	}, [])

	const loginViaGoogleHandler = useCallback(() => {
		ampEventWithEventProperty(
			AnalyticsEventType.SIGN_IN_ATTEMPTED,
			AnalyticsParams.signInAttempted('google'),
		)
		dispatch(setLoginProvider('google'))
		window.open(`${process.env.REACT_APP_SERVER_URL}api/auth/google`, '_top')
	}, [dispatch])

	const loginViaFacebookHandler = useCallback(() => {
		ampEventWithEventProperty(
			AnalyticsEventType.SIGN_IN_ATTEMPTED,
			AnalyticsParams.signInAttempted('Fb'),
		)
		dispatch(setLoginProvider('Fb'))
		window.open(`${process.env.REACT_APP_SERVER_URL}api/auth/facebook`, '_top')
	}, [dispatch])

	const passwordLoginValidationSchema = useMemo(
		() =>
			Yup.object({
				password: Yup.string()
					.required('Please enter your password')
					.test('server valid', 'Incorrect password', async password => {
						if (!password) return
						const hashedPassword = await hashPassword(password)
						try {
							await loginUser({
								email: loginEmail,
								password: hashedPassword,
							})

							return true
						} catch (error) {
							return false
						}
					}),
			}),
		[loginEmail],
	)

	const onSubmitCreateAccountForm = useCallback(
		async values => {
			try {
				const hashedPassword = await hashPassword(values.password)

				await createUser({
					variables: {
						user: {
							email: values.email,
							first_name: values.first_name,
							last_name: values.last_name,
							gender: values.gender,
							password: hashedPassword,
						},
					},
				})
				// console.log(description)
				// await dispatch(createUserThunk({ ...values, password: hashedPassword }))
				setIsNewUser(true)
				await dispatch(
					loginUserThunk({ email: values.email, password: hashedPassword }),
				)
				await dispatch(getUserInfoThunk())
			} catch (error) {
				errorToast('Create account error')
			}
		},
		[createUser, dispatch],
	)

	useEffect(() => {
		const { PAGE_NAME, EVENT_NAME } = AmpArgsByAuthState[authState]
		ampEventWithEventProperty(EVENT_NAME, AnalyticsParams.pageView(PAGE_NAME))
	}, [authState])

	return !isNewUser && isUserModerator ? (
		<Navigate to='/moderator/dashboard' />
	) : !isNewUser && isUserAdminOrWriter ? (
		<Navigate to='/writer/profile' />
	) : (
		<Grid
			templateColumns='repeat(2, 1fr)'
			h='100%'
			bg="url('/images/authorization/background.jpg')"
			bgSize='100vw 100vh'
		>
			<Flex
				align='center'
				justify='center'
				w='100%'
				h='100%'
				direction='column'
			>
				<Box>
					<Heading>{PLATFORM_NAME}</Heading>

					<Text lineHeight='22px' letterSpacing='-0.408px' p='7px'>
						Writer&apos;s platform
					</Text>
				</Box>
			</Flex>

			<Flex
				w='100%'
				p='31px 150px 31px 40px'
				justify='center'
				align='center'
				className='authorization-form-wrapper'
				borderRadius='5px'
			>
				{isNewUser ? (
					<WelcomeNewUserWindow />
				) : (
					<AuthForm
						authState={authState}
						isValidatedEmail={isValidatedEmail}
						resetPasswordEmail={!!state?.resetPasswordEmail}
						isShowLoading={isShowLoading}
						onSubmitLoginEmail={onSubmitLoginEmail}
						onSubmitLoginPassword={onSubmitLoginPassword}
						passwordLoginValidationSchema={passwordLoginValidationSchema}
						loginViaGoogleHandler={loginViaGoogleHandler}
						loginViaFacebookHandler={loginViaFacebookHandler}
						loginEmail={loginEmail}
						signInAnotherAccountHandler={signInAnotherAccountHandler}
						onSubmitCreateAccountForm={onSubmitCreateAccountForm}
						loginButtonHandler={loginButtonHandler}
						signUpLinkHandler={signUpLinkHandler}
						handleResetPassword={handleResetPassword}
						fbABTest={fbABTest}
					/>
				)}
			</Flex>
		</Grid>
	)
}

export default AuthorizationPage
