import {
	createUser,
	getUserInfo,
	loginUser,
	updateUser,
	updateUserImage,
} from '../../../../api/user'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const createUserThunk = createAsyncThunk(
	'user/createUser',
	async user => {
		try {
			const res = await createUser(user)
			return res.data
		} catch (error) {
			throw new Error('Create user Error')
		}
	},
)

export const loginUserThunk = createAsyncThunk('user/loginUser', async user => {
	try {
		const res = await loginUser(user)
		const { access_token } = res.data
		if (access_token) {
			return { access_token: 'Bearer ' + access_token }
		}
		throw new Error()
	} catch (error) {
		throw new Error('Login user Error')
	}
})

export const logoutUserThunk = createAsyncThunk('user/logout', () => {
	window.userEmail = undefined
	window.db_userId = undefined
})

export const getUserInfoThunk = createAsyncThunk(
	'user/getUserInfo',
	async () => {
		try {
			const res = await getUserInfo()
			return res.data
		} catch (error) {
			throw new Error('Fetch user info Error')
		}
	},
)

export const updateUserThunk = createAsyncThunk(
	'user/updateUser',
	async user => {
		try {
			const res = await updateUser(user)
			return res.data
		} catch (error) {
			throw new Error('Update user Error')
		}
	},
)

export const updateUserImageThunk = createAsyncThunk(
	'user/updateUserImage',
	async formdata => {
		try {
			const res = await updateUserImage(formdata)
			const { link } = res.data
			return { link }
		} catch (error) {
			throw new Error(error)
		}
	},
)
