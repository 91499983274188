import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/UI/Button'
import * as R from 'ramda'
import { ampEventWithEventProperty } from '../../../../utils/amplitude'
import { AnalyticsEventType } from '../../../../model/analytics'
import { Flex } from '@chakra-ui/react'

const SubmitButton = ({ onClick }) => {
	const { t } = useTranslation()
	const { errors } = useFormikContext()

	const buttonHandler = () => {
		onClick && onClick()

		if (!R.isEmpty(errors)) {
			ampEventWithEventProperty(AnalyticsEventType.SIGN_IN_ERROR, {
				source: 'email',
				error_message: Object.values(errors)[0][0],
			})
		}
	}

	return (
		<Flex m='30px 0 50px' w='100%' justify='flex-end' align='center'>
			<Button type='submit' onClick={buttonHandler}>
				{t('Continue')}
			</Button>
		</Flex>
	)
}

export default SubmitButton
