import axiosApiInstance from 'utils/axios-api-instance'

export const createBook = book => axiosApiInstance.post(`api/books`, book)

export const fetchAllBooks = () => axiosApiInstance.get(`api/books/admin/full`)

export const fetchUserBooks = () => axiosApiInstance.get(`api/books`)

export const updateBook = body => axiosApiInstance.put(`api/books`, body)

export const updateBookStatus = body =>
	axiosApiInstance.put(`api/books/moderator`, body)

export const updateBookCover = ({ formdata, book_id }) =>
	axiosApiInstance.post(`api/file/upload/book/${book_id}`, formdata)

export const updateBookFullScreenCover = ({ formdata, book_id }) =>
	axiosApiInstance.post(`api/file/upload/book/full_screen/${book_id}`, formdata)

export const deleteBook = body => axiosApiInstance.put(`api/books`, body)

export const addBookExclusiveContract = ({ formdata, book_id }) =>
	axiosApiInstance.post(`/api/external/contracts/${book_id}`, formdata)
