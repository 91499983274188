import { lazy } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useRoutes } from 'react-router-dom'
import {
	getIsUserAdmin,
	getRedirectRouteByRole,
} from 'redux/slices/user/selectors'

const BookModerationRequestsPage = lazy(() =>
	import('pages/BookModeration/Requests'),
)
const BookModerationDetailsPage = lazy(() =>
	import('pages/BookModeration/BookDetails'),
)
const BookModerationChapterDifferencePage = lazy(() =>
	import('pages/BookModeration/ChapterDifference'),
)

const AdminRoutes = () => {
	const routes = useRoutes([
		{
			path: '*',
			element: <Navigate to='/writer/profile' />,
		},
		{
			path: 'book-moderation',
			children: [
				{
					path: ':request_id',
					children: [
						{ index: true, element: <BookModerationDetailsPage /> },
						{
							path: 'chapter-diff/:chapter_id',
							element: <BookModerationChapterDifferencePage />,
						},
					],
				},
				{ index: true, element: <BookModerationRequestsPage /> },
			],
		},
	])

	const isUserAdmin = useSelector(getIsUserAdmin)
	const redirectRoute = useSelector(getRedirectRouteByRole)

	return isUserAdmin ? routes : <Navigate to={redirectRoute} />
}

export default AdminRoutes
