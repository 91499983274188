import { cssVar } from '@chakra-ui/theme-tools'

const $bg = cssVar('tooltip-bg')
const $arrowBg = cssVar('popper-arrow-bg')

export const tooltipStyles = {
	baseStyle: {
		[$bg.variable]: '#141414',
		[$arrowBg.variable]: '#141414',
		arrowBg: '#141414',
		borderRadius: '12px',
		px: '20px',
		py: '12px',
		fontSize: '11px',
		lineHeight: '15px',
		color: '#B7B9BC',
		maxW: '208px',
	},
}
