import { AnalyticsEventType, AnalyticsParams } from 'model/analytics'
import {
	createChapter,
	fetchBookChapters,
	reorderBookChapters,
	updateChapter,
	updateChapterStatusByModerator,
	updateChapterStatusByWriter,
	updateProtectedFieldsChapter,
	uploadChapters,
} from 'api/chapters'

import { UserRoles } from 'constants/user'
import { ampEventWithEventProperty } from 'utils/amplitude'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import createHtml from 'utils/createHtml'
import { insertChapterTextData } from 'api/files'
import { v4 as uuidv4 } from 'uuid'
import { MAX_EDITOR_WORDS_COUNT } from 'constants/values'

export const fetchBookChaptersThunks = createAsyncThunk(
	'chapters/fetchBookChapters',
	async book_id => {
		try {
			const res = await fetchBookChapters(book_id)

			const chapters = res.data
			return { book_id, chapters }
		} catch (error) {
			throw new Error('Fetch Book Chapters Error')
		}
	},
)

export const fetchChapterText = createAsyncThunk(
	'chapters/fetchText',
	async ({ book_id, chapter_id, bucket_link }) => {
		try {
			const res = await axios.get(`${bucket_link}?caching=${uuidv4()}`)
			const text = res.data

			return { book_id, chapter_id, text }
		} catch (error) {
			throw new Error('Fetch Book Chapters Error')
		}
	},
)

export const createChapterThunk = createAsyncThunk(
	'chapters/createChapter',
	async ({ source, chapter }) => {
		try {
			const res = await createChapter(chapter)
			const data = await res.data
			const { chapter_id } = data

			const htmlFile = createHtml()

			const res1 = await insertChapterTextData({ chapter_id, htmlFile })
			const { link } = res1.data

			return { source, chapter: { ...data, bucket_link: link, text: '' } }
		} catch (error) {
			throw new Error('Create Book Chapter Error')
		}
	},
)

export const publishNowChapterThunk = createAsyncThunk(
	'chapters/publishNow',
	async ({
		status,
		chapter_id,
		title,
		price,
		text,
		book_id,
		word_count_delta,
		userRole,
	}) => {
		try {
			const updateChapterBody = {
				chapter_id,
				title,
				price,
				text,
				book_id,
			}
			const updateChapterStatusBody = {
				chapter_id,
				status,
			}

			const htmlFile = createHtml(text)

			await insertChapterTextData({ chapter_id, htmlFile })

			await updateChapter(updateChapterBody)
			if (userRole === UserRoles.WRITER)
				await updateChapterStatusByWriter(updateChapterStatusBody)

			if (userRole === UserRoles.ADMIN)
				await updateChapterStatusByModerator(updateChapterStatusBody)

			return {
				status,
				chapter_id,
				title,
				price,
				text,
				book_id,
				word_count_delta,
			}
		} catch (error) {
			ampEventWithEventProperty(
				AnalyticsEventType.PUBLISH_NOW_FAILED,
				AnalyticsParams.publishNowFail(book_id, chapter_id),
			)
			throw new Error(error.message)
		}
	},
)

export const saveAsDraftChapterThunk = createAsyncThunk(
	'chapters/saveAsDraft',
	async ({
		status,
		chapter_id,
		title,
		price,
		text,
		book_id,
		word_count_delta,
		userRole,
	}) => {
		try {
			const updateChapterBody = {
				chapter_id,
				title,
				price,
				text,
				book_id,
			}

			const updateChapterStatusBody = {
				chapter_id,
				status,
			}

			const htmlFile = createHtml(text)

			await insertChapterTextData({ chapter_id, htmlFile })

			await updateChapter(updateChapterBody)
			if (userRole === UserRoles.WRITER)
				await updateChapterStatusByWriter(updateChapterStatusBody)

			if (userRole === UserRoles.ADMIN)
				await updateChapterStatusByModerator(updateChapterStatusBody)

			return {
				status,
				chapter_id,
				title,
				price,
				text,
				book_id,
				word_count_delta,
			}
		} catch (error) {
			ampEventWithEventProperty(
				AnalyticsEventType.SAVE_AS_DRAFT_FAILED,
				AnalyticsParams.saveAsDraftFail(book_id, chapter_id, word_count_delta),
			)
			throw new Error(error.message)
		}
	},
)

export const deleteChapterThunk = createAsyncThunk(
	'chapters/deleteChapter',
	async ({ book_id, source, chapter_id }) => {
		try {
			await updateChapter({ chapter_id, deleted: true })
			return { book_id, chapter_id, source }
		} catch (error) {
			throw new Error('Update chapter Error')
		}
	},
)

export const updateProtectedFieldsChapterThunk = createAsyncThunk(
	'chapters/adminUpdate',
	async ({ chapter, book_id }) => {
		try {
			await updateProtectedFieldsChapter(chapter)
			return { book_id, ...chapter }
		} catch (error) {
			throw new Error(`Can't update chapter protected fields`)
		}
	},
)

export const reorderBookChaptersThunk = createAsyncThunk(
	'chapters/reorder',
	async ({ book_id, chapters }) => {
		try {
			const res = await reorderBookChapters(chapters)
			return { book_id, chapters: res.data.data }
		} catch (error) {
			throw new Error('Reorder chapters error')
		}
	},
)

export const uploadChaptersThunk = createAsyncThunk(
	'chapters/upload',
	async ({ formdata, book_id }) => {
		try {
			const res = await uploadChapters({
				formdata,
				book_id,
			})
			const { chapters } = res.data
			return { chapters, book_id }
		} catch (error) {
			const exeedsLimit = error.response.data?.errors?.wordsLimitExceeded ?? []
			const message =
				exeedsLimit.length > 0
					? `Chapter${exeedsLimit.length > 1 ? 's' : ''} ${exeedsLimit
							.map(item => item.title)
							.join(', ')} exceed${
							exeedsLimit.length > 1 ? '' : 's'
					  } the ${MAX_EDITOR_WORDS_COUNT} word limit. Fix it and upload episodes again.`
					: error.response.data.message
			throw new Error(message)
		}
	},
)
