export const AnalyticsEventType = Object.freeze({
	PAGE_VIEW: 'page_view',
	PAGE_VIEW_OPENED: 'page_view_opened',
	SIGN_IN_ATTEMPTED: 'sign_in_attempted',
	SIGN_IN_SUCCESS: 'sign_in_success',
	ACCOUNT_CREATION_STARTED: 'account_creation_started',
	ACCOUNT_CREATED: 'account_created',
	SIGN_IN_ERROR: 'sign_in_error',
	PROFILE_PICTURE_UPLOAD_FAIL: 'profile_picture_upload_fail',
	PROFILE_PICTURE_UPLOAD_SUCCESS: 'profile_picture_upload_success',
	PROFILE_EDIT_STARTED: 'profile_edit_started',
	STORY_CREATION_STARTED: 'story_creation_started',
	WITHDRAW_ATTEMPTED: 'withdraw_attempted',
	BOOK_OPEN: 'book_open',
	DELETE_STORY_ATTEMPTED: 'delete_story_attempted',
	DELETE_STORY_SUCCESS: 'delete_story_success',
	DELETE_STORY_FAIL: 'delete_story_fail',
	VIEW_AS_READER: 'view_as_reader',
	STORY_CREATED: 'story_created',
	STORY_PICTURE_UPLOAD_SUCCESS: 'story_picture_upload_success',
	STORY_PICTURE_UPLOAD_ERROR: 'story_picture_upload_error',
	STORY_UPDATED: 'story_updated',
	STORY_UPDATE_ERROR: 'story_update_error',
	BOOK_EPISODES_OPENED: 'book_episodes_opened',
	CHAPTER_OPENED: 'chapter_opened',
	MANAGE_STORY_PRESSED: 'manage_story_pressed',
	BOOK_MANAGE_STORY_OPENED: 'book_manage_story_opened',
	NEW_EPISODE_FAIL: 'new_episodes_fail',
	NEW_EPISODE_SUCCESS: 'new_episodes_success',
	NEW_EPISODE_ATTEMPT: 'new_episode_attempt',
	PUBLISH_NOW: 'publish_now',
	DELETE_CHAPTER_ATTEMPTED: 'delete_chapter_attempted',
	DELETE_CHAPTER_SUCCESS: 'delete_chapter_success',
	DELETE_CHAPTER_FAIL: 'delete_chapter_fail',
	SAVE_AS_DRAFT_ATTEMPTED: 'save_as_draft_attempted',
	SAVE_AS_DRAFT_SUCCESS: 'save_as_draft_success',
	SAVE_AS_DRAFT_FAILED: 'save_as_draft_failed',
	PUBLISH_NOW_ATTEMPTED: 'publish_now_attempted',
	PUBLISH_NOW_SUCCESS: 'publish_now_success',
	PUBLISH_NOW_FAILED: 'publish_now_failed',
	PASSWORD_RESET_ATTEMPTED: 'password_reset_attempt',
	SIGN_IN_DIFFERENT_ACC_ATTEMPT: 'sign_in_different_acc_attempt',
})

export class AnalyticsParams {
	static pageView(page) {
		return { page }
	}

	static signInAttempted(source) {
		return { source }
	}

	static signInSuccess(source) {
		return { source }
	}

	static accountCreationStarted(source) {
		return { source }
	}

	static accountCreated(source) {
		return { source }
	}

	static profilePictureUploadFail(message) {
		return { message }
	}

	static profileEditStarted(source) {
		return { source }
	}

	static storyCreationStarted(source) {
		return { source }
	}

	static bookOpen(book_id) {
		return { book_id }
	}

	static deleteStoryAttempted(source, book_id) {
		return { source, book_id }
	}

	static viewAsReader(source, book_id) {
		return { source, book_id }
	}

	static deleteStorySuccess(source, book_id) {
		return { source, book_id }
	}

	static deleteStoryFail(source, book_id) {
		return { source, book_id }
	}

	static storyPictureUploadError(message) {
		return { message }
	}

	static storyUpdateError(message) {
		return { message }
	}

	static bookPageOpened(source, book_id) {
		return { source, book_id }
	}

	static chapterOpened(source, chapter_id, book_id) {
		return { source, chapter_id, book_id }
	}

	static manageStoryPressed(source, book_id) {
		return { source, book_id }
	}

	static bookEpisodesOpened(source, book_id) {
		return { source, book_id }
	}

	static bookManageStoriesOpened(source, book_id) {
		return { source, book_id }
	}

	static newEpisodeAttempt(source, book_id) {
		return { source, book_id }
	}

	static newEpisodeSuccess(source, book_id) {
		return { source, book_id }
	}

	static newEpisodeFail(source, book_id) {
		return { source, book_id }
	}

	static delteChapterAttempted(source, book_id, chapter_id) {
		return { source, book_id, chapter_id }
	}

	static deleteChapterSuccess(source, book_id, chapter_id) {
		return { source, book_id, chapter_id }
	}

	static deleteChapterFail(source, book_id, chapter_id) {
		return { source, book_id, chapter_id }
	}

	static saveAsDraftAttempted(book_id, chapter_id, word_count_delta) {
		return { book_id, chapter_id, word_count_delta }
	}

	static saveAsDraftSuccess(book_id, chapter_id, word_count_delta) {
		return { book_id, chapter_id, word_count_delta }
	}

	static saveAsDraftFail(book_id, chapter_id, word_count_delta) {
		return { book_id, chapter_id, word_count_delta }
	}

	static publishNowAttempted(book_id, chapter_id, word_count_delta) {
		return { book_id, chapter_id, word_count_delta }
	}

	static publishNowSuccess(book_id, chapter_id, word_count_delta) {
		return { book_id, chapter_id, word_count_delta }
	}

	static publishNowFail(book_id, chapter_id, word_count_delta) {
		return { book_id, chapter_id, word_count_delta }
	}
}
