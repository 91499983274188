import React from 'react'
import { useTranslation } from 'react-i18next'
import EmailTemplates from '../../../../constants/emailTemplates'
import FormControl from '../FormControl'
import { Form, Formik } from 'formik'
import { validateYupSchemaMultiErrors } from '../../../../utils/yupUtils'
import LoadingSpinner from '../../../../components/UI/LoadingSpinner'
import SubmitButton from '../SubmitButton'
import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react'
import { passwordFormLinkStyle } from 'shared/utils/chakra-theme/components/link'
import { customFormStyle } from 'shared/utils/chakra-theme/components/formStyle'

const LoginPasswordForm = ({
	isShowLoading,
	loginEmail,
	passwordLoginValidationSchema,
	onSubmitLoginPassword,
	signInAnotherAccountHandler,
	loginButtonHandler,
	handleResetPassword,
}) => {
	const { t } = useTranslation()

	return (
		<Formik
			validateOnBlur={false}
			validateOnChange={false}
			initialValues={{
				password: '',
			}}
			validate={values =>
				validateYupSchemaMultiErrors(values, passwordLoginValidationSchema)
			}
			onSubmit={onSubmitLoginPassword}
		>
			<Form style={{ ...customFormStyle, padding: '65px 63px 58px' }}>
				<Box mb='30px'>
					<Heading size='xs' variant='dark' mb='27px'>
						{t('Enter your password')}
					</Heading>

					<Text
						variant='dark'
						d='inline-block'
						fontWeight='600'
						lineHeight='110%'
					>
						{loginEmail}
					</Text>
				</Box>

				<FormControl
					type='password'
					htmlForId='email'
					name='password'
					maxLength='20'
					label={t('Password')}
				/>

				<SubmitButton onClick={loginButtonHandler} />

				<Flex
					direction='column'
					gap='20px'
					borderTop='1px solid #dbdbdb'
					pt='21px'
				>
					<Link
						href={EmailTemplates.RESET_PASSWORD}
						target='_blank'
						rel='noreferrer'
						sx={passwordFormLinkStyle}
						onClick={handleResetPassword}
					>
						Reset your password
					</Link>

					<Text
						onClick={signInAnotherAccountHandler}
						sx={passwordFormLinkStyle}
					>
						Sign in to a different account
					</Text>
				</Flex>

				{isShowLoading && <LoadingSpinner />}
			</Form>
		</Formik>
	)
}

export default LoginPasswordForm
