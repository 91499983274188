import { ApolloClient, ApolloProvider, createHttpLink } from '@apollo/client'

import React from 'react'
import { setContext } from '@apollo/client/link/context'
import { store } from 'redux/store'
import { cache } from './cache'
import { PLATFORM_NAME } from 'constants/common'

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_API_GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
	const { access_token } = store.getState().user

	return {
		headers: {
			...headers,
			Authorization: access_token ? `${access_token}` : '',
		},
	}
})

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: cache,
	name: PLATFORM_NAME,
})

const WithApollo = ({ children }) => (
	<ApolloProvider client={client}>{children}</ApolloProvider>
)

export default WithApollo
