const createHtml = (text = '') => {
	const htmlFileBlob = new Blob([text], {
		type: 'text/html',
	})

	const htmlFile = new FormData()
	htmlFile.append('file', htmlFileBlob, 'data.html')

	return htmlFile
}

export default createHtml
