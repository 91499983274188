import { colors } from '../colors'

export const textStyle = {
	sizes: {
		11: {
			fontSize: '11px',
		},
		12: {
			fontSize: '12px',
		},
		13: {
			fontSize: '13px',
		},
		14: {
			fontSize: '14px',
		},
		15: {
			fontSize: '15px',
		},
		16: {
			fontSize: '16px',
		},
		17: {
			fontSize: '17px',
		},
		18: {
			fontSize: '18px',
		},
		20: {
			fontSize: '20px',
		},
		22: {
			fontSize: '22px',
		},
		23: {
			fontSize: '23px',
		},
		24: {
			fontSize: '24px',
		},
		32: {
			fontSize: '32px',
		},
		40: {
			fontSize: '40px',
		},
	},
	variants: {
		info: {
			color: '#484964',
		},
		pink: {
			color: '#d0006e',
		},
		white: {
			color: '#f4f4f4',
		},
		dark: {
			color: '#323232',
		},
		'main-dark': {
			color: colors['main-dark'],
		},
		grey: {
			color: '#5d5e60',
		},
		grey69: {
			color: '#696969',
		},
		greyBC: {
			color: '#bcbcbc',
		},
		grey5e: {
			color: '#5e5e5e',
		},
		error: {
			color: '#ea4335',
		},
		draft: {
			color: '#848689',
		},
		approved: {
			color: '#0052dd',
		},
		black: {
			color: '#000',
		},
		white100: {
			color: 'white',
		},
		blue: {
			color: colors.blue,
		},
		chapter: {
			color: '#b7b9bc',
		},
		chart: {
			color: '#808285',
		},
		green: {
			color: '#009758',
		},
	},
	defaultProps: {
		size: '16',
		variant: 'white',
	},
}
