import { Flex, Heading, Text } from '@chakra-ui/react'
import Button from 'components/UI/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const WelcomeNewUserWindow = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const redirectOnProfile = () => {
		navigate('/writer/profile')
	}

	return (
		<Flex
			maxW='558px'
			p='65px 63px 48px'
			direction='column'
			width='100%'
			boxShadow='0 0 7px rgba(180, 182, 192, 0.19)'
			borderRadius='5px'
			bg='#fff'
		>
			<Heading size='xs' variant='dark' mb='27px'>
				{t('Account created')}
			</Heading>

			<Text variant='dark' fontWeight='600' lineHeight='110%' mb='130px'>
				{t(
					'We are glad you joined us, get to know the platform and start your great journey as a writer with Passion!',
				)}
			</Text>

			<Flex w='100%' justify='flex-end' align='center'>
				<Button type='button' onClick={redirectOnProfile}>
					{t('Let’s Get Published!')}
				</Button>
			</Flex>
		</Flex>
	)
}

export default WelcomeNewUserWindow
