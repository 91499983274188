import { InMemoryCache } from '@apollo/client'
import { readChapterText } from 'apollo-state/chapter'

export const cache = new InMemoryCache({
	typePolicies: {
		Chapter: {
			fields: {
				text: {
					read: (_, { variables }) => {
						const chapter_id = variables.chapter_id
						return readChapterText(chapter_id)
					},
				},
			},
		},
	},
})
