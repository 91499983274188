import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { Link, Text } from '@chakra-ui/react'

const TermsAndPrivacy = () => {
	const { t } = useTranslation()
	const { submitCount } = useFormikContext()

	return (
		<Text
			size='13'
			variant='grey'
			lineHeight='22px'
			fontWeight='600'
			mt='3px'
			mb={submitCount ? '33px' : '50px'}
		>
			{t(
				'By clicking Create account, I agree that I have read and accepted the ',
			)}
			<Link
				href='/info/terms'
				target='_blank'
				rel='noreferrer'
				textDecoration='none'
				color='#0052dd'
				_hover={{ textDecoration: 'none' }}
			>
				{t('Terms of Use')}
			</Link>
			{t(' and ')}
			<Link
				href='/info/privacy_policy'
				target='_blank'
				rel='noreferrer'
				textDecoration='none'
				color='#0052dd'
				_hover={{ textDecoration: 'none' }}
			>
				{t('Privacy Policy')}
			</Link>
		</Text>
	)
}

export default TermsAndPrivacy
