export const MAX_ABOUT_ME_LENGTH = 300
export const MIN_PASSWORD_LENGTH = 8
export const MAX_FIRST_NAME_LENGTH = 15
export const MAX_LAST_NAME_LENGTH = 20
export const MAX_MONTH_DAY = 31
export const MIN_YEAR = 1944
export const MAX_EDITOR_WORDS_COUNT = 5000
export const MAX_DISPLAY_TAGS_COUNT = 4
export const DEBOUNCE_DELAY = 300
export const THROTTLE_DELAY = 300
