import * as EmailValidator from 'email-validator'
import * as Yup from 'yup'

import {
	DEBOUNCE_DELAY,
	MAX_FIRST_NAME_LENGTH,
	MAX_LAST_NAME_LENGTH,
	// MAX_MONTH_DAY,
	MIN_PASSWORD_LENGTH,
	// MIN_YEAR,
} from 'constants/values'

import { AnalyticsEventType } from 'model/analytics'
import { debounce } from 'lodash'
import { getIsAlreadyExistEmail } from 'api/user'

export const AuthStatesTypes = {
	SIGN_IN: 'sign-in',
	SIGN_UP: 'sign-up',
	RESET_PASSWORD: 'reset-password',
}

export const AmpArgsByAuthState = {
	[AuthStatesTypes.SIGN_IN]: {
		PAGE_NAME: AuthStatesTypes.SIGN_IN,
		EVENT_NAME: AnalyticsEventType.PAGE_VIEW_OPENED,
	},
	[AuthStatesTypes.SIGN_UP]: {
		PAGE_NAME: AuthStatesTypes.SIGN_UP,
		EVENT_NAME: AnalyticsEventType.PAGE_VIEW_OPENED,
	},
	[AuthStatesTypes.RESET_PASSWORD]: {
		PAGE_NAME: AuthStatesTypes.RESET_PASSWORD,
		EVENT_NAME: AnalyticsEventType.PAGE_VIEW_OPENED,
	},
}

export const emailLoginValidationSchema = Yup.object({
	email: Yup.string()
		.required('Required')
		.test(
			'server valid',
			'Invalid email address',
			async value => value && EmailValidator.validate(value),
		)
		.test(
			'server valid',
			"An account with this email address doesn't exists",
			value => value && getIsAlreadyExistEmail(value),
		),
})

export const createAccountValidationSchema = Yup.object({
	email: Yup.string()
		.required('Required')
		.test(
			'server valid',
			'Invalid email address',
			async value => value && EmailValidator.validate(value),
		)
		.test(
			'server valid',
			'An account with this email address already exists',
			debounce(async value => {
				if (value) {
					const res = await getIsAlreadyExistEmail(value)
					return !res
				}
				return false
			}, DEBOUNCE_DELAY),
		),
	first_name: Yup.string()
		.max(MAX_FIRST_NAME_LENGTH, 'Must be 15 characters or less')
		.required('Required'),
	last_name: Yup.string()
		.max(MAX_LAST_NAME_LENGTH, 'Must be 20 characters or less')
		.required('Required'),
	password: Yup.string()
		.min(MIN_PASSWORD_LENGTH, 'Password should be at least 8 characters')
		.matches(
			/^(?=.*[0-9]+.*)(?=.*[a-zA-Zа-яА-Я]+.*)[0-9a-zA-Zа-яА-Я\S]{2,}$/gm,
			'Password should be at least one number, one letter and no spaces',
		)
		.required('Required'),
	// month: Yup.string().required('Required'),
	// day: Yup.number()
	// 	.min(1, 'error')
	// 	.max(MAX_MONTH_DAY, 'error')
	// 	.required('Required'),
	// year: Yup.number()
	// 	.min(MIN_YEAR, 'error')
	// 	.max(new Date().getFullYear(), 'error')
	// 	.required('Required'),
	gender: Yup.string().required('Required'),
})
