import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import genders from '../../../../constants/genders'
import { Flex, FormLabel, RadioGroup, Text } from '@chakra-ui/react'

const GenderFormControl = () => {
	const [field, meta] = useField({ name: 'gender' })
	const { t } = useTranslation()

	return (
		<Flex pos='relative' mt='-2px' pb='37px' direction='column'>
			<Text
				size='12'
				variant='grey'
				fontWeight='600'
				lineHeight='110%'
				mb='2px'
			>
				{t('Gender')}
			</Text>

			<RadioGroup
				display='grid'
				mt='5px'
				gridTemplateColumns='repeat(3, auto)'
				gap='30px'
				alignItems='center'
			>
				{genders.map((gender, idx) => (
					<Flex key={idx} align='center'>
						<input
							name='gender'
							style={{ marginRight: '6px' }}
							type='radio'
							checked={field.value === gender.value}
							id={gender.value}
							{...field}
							value={gender.value}
						/>
						<FormLabel
							fontWeight='600'
							fontSize='14px'
							lineHeight='110%'
							color='#0b080f'
							m='0'
							className='gender-option__label'
							htmlFor={gender.value}
						>
							{t(gender.name)}
						</FormLabel>
					</Flex>
				))}
			</RadioGroup>
			{meta.error && <p className='form-control__error'>{meta.error[0]}</p>}
		</Flex>
	)
}

export default GenderFormControl
