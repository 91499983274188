import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../../../../components/UI/LoadingSpinner'
import Button from '../../../../components/UI/Button'
import {
	MAX_FIRST_NAME_LENGTH,
	MAX_LAST_NAME_LENGTH,
} from '../../../../constants/values'
import { validateYupSchemaMultiErrors } from '../../../../utils/yupUtils'
import { createAccountValidationSchema } from '../../models'
// import DateDayFormControl from '../DateDayFormControl'
// import DateMonthFormControl from '../DateMonthFormControl'
// import DateYearFormControl from '../DateYearFormControl'
import FormControl from '../FormControl'
import GenderFormControl from '../GenderFormControl'
import NameFormControl from '../NameFormControl'
import PasswordFormControl from '../PasswordFormControl'
import TermsAndPrivacy from '../TermsAndPrivacy'
import { customFormStyle } from 'shared/utils/chakra-theme/components/formStyle'
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react'
import { customLinkStyle } from 'shared/utils/chakra-theme/components/link'

const SignUpForm = ({ onSubmitCreateAccountForm, isShowLoading }) => {
	const { t } = useTranslation()
	return (
		<Formik
			validateOnChange={true}
			initialValues={{
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				// month: 'January',
				// year: '2000',
				// day: '1',
				gender: 'MALE',
			}}
			validate={values =>
				validateYupSchemaMultiErrors(values, createAccountValidationSchema)
			}
			onSubmit={onSubmitCreateAccountForm}
		>
			<Form style={{ ...customFormStyle, padding: '65px 63px 30px' }}>
				{isShowLoading && <LoadingSpinner />}

				<Box mb='30px'>
					<Heading size='xs' variant='dark' mb='27px'>
						{t('Create an account')}
					</Heading>

					<Text
						variant='dark'
						display='inline-block'
						fontWeight='600'
						lineHeight='110%'
					>
						{t('Already have an account?')}
					</Text>

					<Link to='/auth/sign-in' style={customLinkStyle}>
						{t('Sign in')}
					</Link>
				</Box>

				<FormControl
					htmlForId='email'
					label={t('Email address')}
					name='email'
				/>

				<Grid gap='21px' templateColumns='1fr 1fr'>
					<NameFormControl
						htmlForId='first-name'
						label={t('First name')}
						name='first_name'
						maxLength={MAX_FIRST_NAME_LENGTH}
					/>

					<NameFormControl
						htmlForId='last-name'
						label={t('Last name')}
						name='last_name'
						maxLength={MAX_LAST_NAME_LENGTH}
					/>
				</Grid>

				<PasswordFormControl />

				{/* <Text
					size='12'
					variant='grey'
					mb='10px'
					lineHeight='110%'
					fontWeight='600'
				>
					Date of birth
				</Text> */}

				{/* <Grid gap='20px' templateColumns='2fr 1fr 1fr'>
					<DateMonthFormControl />
					<DateDayFormControl />
					<DateYearFormControl />
				</Grid> */}

				<GenderFormControl />

				<TermsAndPrivacy />

				<Flex w='100%' justify='flex-end' align='center'>
					<Button type='submit'>{t('Create account')}</Button>
				</Flex>
			</Form>
		</Formik>
	)
}

export default SignUpForm
