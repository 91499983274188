import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import FormControl from '../FormControl'
import GoogleButton from '../GoogleButton'
import FacebookButton from '../FacebookButton'
import { Form, Formik } from 'formik'
import { validateYupSchemaMultiErrors } from '../../../../utils/yupUtils'
import LoadingSpinner from '../../../../components/UI/LoadingSpinner'
import SubmitButton from '../SubmitButton'
import { emailLoginValidationSchema } from '../../models'
import SignUpTermsAndPrivacy from '../SignUpTermsAndPrivacy'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { customFormStyle } from 'shared/utils/chakra-theme/components/formStyle'
import { customLinkStyle } from 'shared/utils/chakra-theme/components/link'

const LoginEmailForm = ({
	isShowLoading,
	resetPasswordEmail,
	onSubmitLoginEmail,
	loginViaGoogleHandler,
	loginViaFacebookHandler,
	signUpLinkHandler,
	fbABTest,
}) => {
	const { t } = useTranslation()

	return (
		<Formik
			validateOnChange={false}
			initialValues={{
				email: '',
			}}
			validateOnBlur={false}
			validate={values =>
				validateYupSchemaMultiErrors(values, emailLoginValidationSchema)
			}
			onSubmit={onSubmitLoginEmail}
		>
			<Form style={customFormStyle}>
				<Box mb='30px'>
					<Heading size='xs' variant='dark' mb='27px'>
						{t('Sign In')}
					</Heading>

					<Text
						variant='dark'
						d='inline-block'
						fontWeight='600'
						lineHeight='110%'
					>
						{t('New user?')}
					</Text>

					<Link
						style={customLinkStyle}
						to='/auth/sign-up'
						className='form-header__link'
						onClick={signUpLinkHandler}
					>
						{t('Create an account')}
					</Link>
				</Box>

				<FormControl
					type='email'
					htmlForId='email'
					label={t('Email address')}
					name='email'
				/>

				<SubmitButton />

				{resetPasswordEmail && (
					<Text
						variant='main-dark'
						m='0 0 26px'
						p='17px 50px'
						size='12'
						lineHeight='18px'
						textAlign='center'
						bg='rgba(11, 8, 15, 0.03)'
						borderRadius='7px'
					>
						{t(`Please check your email inbox`)} ({resetPasswordEmail}){' '}
						{t(`for steps to reset your password.`)}
					</Text>
				)}

				<Flex align='center' direction='column' borderTop='1px solid #dbdbdb'>
					<Text
						bg='#fff'
						position='relative'
						top='-8px'
						p='0 29px'
						mb='11px'
						fontWeight='400'
						variant='dark'
						lineHeight='110%'
					>
						Or
					</Text>

					<GoogleButton onClick={loginViaGoogleHandler} />

					{fbABTest && <FacebookButton onClick={loginViaFacebookHandler} />}
				</Flex>

				<SignUpTermsAndPrivacy />

				{isShowLoading && <LoadingSpinner />}
			</Form>
		</Formik>
	)
}

export default LoginEmailForm
