import React from 'react'
import useLockBodyScroll from 'hooks/useLockBodyScroll'

import { Box, Flex } from '@chakra-ui/react/dist/chakra-ui-react.cjs'

const LoadingSpinner = () => {
	useLockBodyScroll()

	return (
		<Flex
			justify='center'
			align='center'
			h='100%'
			w='100%'
			pos='absolute'
			left='0'
			right='0'
			zIndex='1'
			bg='rgba(255, 255, 255, 0.5)'
		>
			<Box
				w='120px'
				h='120px'
				borderRadius='50%'
				border='10px solid #dbdbdbff'
				borderRightColor='transparent'
				d='inline-block'
				animation='0.75s linear 0s infinite normal both running spinner'
				bg='transparent !important'
			/>
		</Flex>
	)
}

export default LoadingSpinner
