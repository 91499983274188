import {
	createBook,
	deleteBook,
	fetchAllBooks,
	fetchUserBooks,
	updateBook,
	updateBookCover,
	updateBookFullScreenCover,
	updateBookStatus,
} from 'api/books'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchModerationBooks } from 'api/moderator'

export const fetchUserBooksThunk = createAsyncThunk(
	'books/fetchBooks',
	async () => {
		try {
			const res = await fetchUserBooks()
			return res.data
		} catch (err) {
			console.error(err)
			throw new Error('Fetch Books Error')
		}
	},
)

export const fetchAllBooksThunk = createAsyncThunk(
	'books/fetchAll',
	async () => {
		try {
			const res = await fetchAllBooks()
			return res.data
		} catch (err) {
			console.error(err)
			throw new Error('Fetch Books Error')
		}
	},
)

export const fetchModerationBooksThunk = createAsyncThunk(
	'books/fetchToModeration',
	async () => {
		try {
			const res = await fetchModerationBooks()
			return res.data
		} catch (error) {
			throw new Error('Fetch books to moderation error')
		}
	},
)

export const createBookThunk = createAsyncThunk(
	'book/createBooks',
	async book => {
		try {
			const res = await createBook(book)
			return res.data
		} catch (err) {
			console.error(err)
			throw err
		}
	},
)

export const deleteBookThunk = createAsyncThunk(
	'book/deleteBook',
	async ({ book_id, source }) => {
		try {
			await deleteBook({ book_id, deleted: true })
			return { book_id, source }
		} catch (error) {
			console.error(error)
			throw error
		}
	},
)

export const updateBookCoverThunk = createAsyncThunk(
	'book/updateBookCover',
	async ({ formdata, book_id }) => {
		try {
			const res = await updateBookCover({
				formdata,
				book_id,
			})
			const { link } = res.data
			return { link, book_id }
		} catch (error) {
			throw new Error(error)
		}
	},
)

export const updateBookFullScreenCoverThunk = createAsyncThunk(
	'book/updateBookFullScreen',
	async ({ formdata, book_id }) => {
		try {
			const res = await updateBookFullScreenCover({
				formdata,
				book_id,
			})
			const { link } = await res.data
			return { link, book_id }
		} catch (error) {
			throw new Error(error)
		}
	},
)

export const updateBookThunk = createAsyncThunk(
	'book/updateBook',
	async book => {
		try {
			await updateBook(book)
			return book
		} catch (error) {
			throw new Error('Update Book Error')
		}
	},
)

export const updateBookStatusThunk = createAsyncThunk(
	'book/updateStatus',
	async book => {
		try {
			await updateBookStatus(book)
			return book
		} catch (error) {
			throw new Error('Update Book Status Error')
		}
	},
)
