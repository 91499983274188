import { toast } from 'react-toastify'
import './styles.scss'
import { ReactComponent as SuccessIcon } from 'assets/revenue/success_icon.svg'

const successToast = message => {
	toast.success(message, {
		position: toast.POSITION.TOP_CENTER,
		icon: <SuccessIcon />,
		hideProgressBar: true,
		closeOnClick: true,
		closeButton: false,
		className: 'toast',
		bodyClassName: 'toastBody',
		style: {
			width: 'fit-content',
		},
		theme: 'dark',
		updateId: '222',
		autoClose: 2000,
	})
}

const errorToast = message => {
	toast.error(message, {
		position: toast.POSITION.TOP_CENTER,
		hideProgressBar: true,
		closeOnClick: true,
		closeButton: false,
		autoClose: 2000,
	})
}

export { errorToast, successToast }
