import { Button, Image } from '@chakra-ui/react'
import React, { memo } from 'react'
import { googleButton } from 'shared/utils/chakra-theme/components/button'

const FacebookButton = ({ onClick }) => (
	<Button
		type='button'
		sx={{
			...googleButton,
			color: '#fff',
			bg: '#425893',
			mb: '0',
		}}
		onClick={onClick}
	>
		<Image
			src='/images/authorization/facebook-logo.svg'
			alt='facebook-logo-login'
			mr='13px'
		/>
		Continue with Facebook
	</Button>
)

export default memo(FacebookButton)
