import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { Link } from 'react-router-dom'
import { Box, Flex, FormLabel, Image, Input, Text } from '@chakra-ui/react'

const FormControl = ({ label, htmlForId, ...props }) => {
	const [field, meta] = useField(props)
	const { t } = useTranslation()
	const isInvalid = meta.touched && meta.error

	const isAlreadyExistAccount = meta.error?.includes(
		'An account with this email address already exists',
	)

	return (
		<Flex pb='37px' pos='relative' direction='column'>
			<FormLabel htmlFor={htmlForId}>{t(label)}</FormLabel>

			<Box pos='relative'>
				<Input
					id={htmlForId}
					borderBottom={isInvalid ? '1px solid #dc6566' : '1px solid #dbdbdb'}
					{...field}
					{...props}
				/>

				{isInvalid && (
					<Image
						src='/images/authorization/error-icon.svg'
						alt='error-validation-icon'
						pos='absolute'
						right='0'
						bottom='3.5px'
					/>
				)}
			</Box>

			{isInvalid && (
				<Text
					size='13'
					variant='error'
					lineHeight='17px'
					fontWeight='600'
					mt='10px'
				>
					{t(meta.error[0])}{' '}
					{field.name === 'email' &&
						isAlreadyExistAccount &&
						meta.error.length === 1 && (
							<Link
								style={{ textDecoration: 'none', color: '#0052dd' }}
								to='/auth/sign-in'
							>
								Sign in
							</Link>
						)}
				</Text>
			)}
		</Flex>
	)
}

export default FormControl
