import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { Box, Flex, FormLabel, Image, Input, Text } from '@chakra-ui/react'

const NameFormControl = ({ label, htmlForId, ...props }) => {
	const [field, meta] = useField(props)
	const { t } = useTranslation()
	const isInvalid = meta.touched && meta.error
	const isValid = field.value && !meta.error

	return (
		<Flex pos='relative' pb='37px' direction='column'>
			<FormLabel
				htmlFor={htmlForId}
				fontSize='12px'
				color='#5d5e60'
				mb='0'
				lineHeight='110%'
				fontWeight='600'
			>
				{t(label)}
			</FormLabel>

			<Box pos='relative'>
				<Input
					id={htmlForId}
					variant='default'
					type='text'
					borderBottom={isInvalid ? '1px solid #dc6566' : '1px solid #dbdbdb'}
					maxLength={60}
					{...field}
					{...props}
				/>

				{isInvalid && (
					<Image
						src='/images/authorization/error-icon.svg'
						alt='error-validation-icon'
						pos='absolute'
						right='0'
						bottom='3.5px'
					/>
				)}

				{isValid && (
					<Image
						src='/images/authorization/check-icon.svg'
						alt='error-validation-icon'
						pos='absolute'
						right='0'
						bottom='3.5px'
					/>
				)}
			</Box>

			{isInvalid && (
				<Text
					size='13'
					variant='error'
					lineHeight='17px'
					fontWeight='600'
					mt='10px'
				>
					{t(meta.error[0])}
				</Text>
			)}
		</Flex>
	)
}

export default NameFormControl
