import { colors } from '../colors'

export const webKitStyle = {
	webkitBoxShadow: '0 0 0 30px white inset !important',
	boxShadow: '0 0 0 30px white inset !important',
	fontSize: '14px',
	lineHeight: '110%',
}

export const autofillStyle = {
	webkitBoxShadow: '0 0 0 30px white inset !important',
	boxShadow: '0 0 0 30px white inset !important',
}

export const inputStyles = {
	baseStyle: {},
	variants: {
		default: {
			field: {
				p: '0 0 4px',
				h: 'fit-content',
				width: '100%',
				fontSize: '14px',
				lineHeight: '110%',
				borderRadius: 'none',
				color: '#0b080f',
				borderBottom: '1px solid #dbdbdb',
				_hover: {
					outline: 'none',
					borderBottom: '1px solid var(--color--blue)',
				},
				_autofill: webKitStyle,
				_focus: {
					outline: 'none',
					borderColor: '#644ded',
					bg: '#FFFFFF',
				},
			},
		},
		editor: {
			field: {
				p: '20px',
				h: '100%',
				width: '100%',
				fontWeight: 800,
				fontSize: '22px',
				lineHeight: '29px',
				textAlign: 'center',
				border: 'none',
				borderRadius: 'none',
				color: colors['main-dark'],
				borderBottom: '1px solid rgba(75, 75, 75, 0.1)',
				_hover: {
					outline: 'none',
				},
				_webkitAutofill: {
					autofillStyle,
					_hover: autofillStyle,
					_focus: autofillStyle,
					_active: autofillStyle,
				},
				_focus: {
					outline: 'none',
				},
			},
		},
		admin: {
			field: {
				h: 'auto',
				w: 'auto',
				fontFamily: `'Nunito Sans', sans-serif`,
				p: '12px 36px',
				bg: '#eeeef2',
				boxSizing: 'border-box',
				fontWeight: 400,
				fontSize: '15px',
				lineHeight: '16px',
				border: '1px solid #eeeef2',
				borderRadius: '20px',
				color: colors.gray,
				caretColor: colors.pink100,
				_hover: {
					outline: 'none',
				},
				_focus: {
					outline: 'none',
					borderColor: colors.pink100,
				},
			},
		},
	},
	defaultProps: {
		variant: 'default',
	},
}
