export const UserRoles = {
	ADMIN: 'ADMIN',
	MODERATOR: 'MODERATOR',
	WRITER: 'WRITER',
	SOURCER: 'SOURCER',
}

export const EmailStatus = {
	APPROVED: 'APPROVED',
	UNAPPROVED: 'UNAPPROVED',
}

export const AuthenticateUserType = Object.freeze({
	LOGIN: 'login',
	REGISTER: 'register',
})

export const REACT_APP_LINKEDIN_STORY_CREATED_CONVERSION_ID = 6869009

export const REACT_APP_LINKEDIN_REGISTRATION_CONVERSION_ID = 6869001
