import { AnalyticsEventType, AnalyticsParams } from '../../../model/analytics'
import {
	ampEvent,
	ampEventWithEventProperty,
	ampSetUserProperty,
} from '../../../utils/amplitude'
import {
	createUserThunk,
	getUserInfoThunk,
	loginUserThunk,
	logoutUserThunk,
	updateUserImageThunk,
	updateUserThunk,
} from './asyncThunks'

import FacebookPixel from 'react-facebook-pixel'
import LinkedInTag from 'react-linkedin-insight'
import { REACT_APP_LINKEDIN_REGISTRATION_CONVERSION_ID } from '../../../constants/user'
import { createSlice } from '@reduxjs/toolkit'
import { publishNowChapterThunk } from '../chapters/asyncThunks'

export const STATE_KEY = 'user'

const initialState = {
	isSendResetPasswordEmail: false,
	first_name: '',
	last_name: '',
	photo_link: '',
	login_provider: '',
	email: '',
	description: '',
	role: '',
	gender: '',
	status: '',
	user_id: '',
	isCreatedUser: false,
	access_token: '',
	authLoginProvider: '',
	fbABTest: false,
	isSubmitSuccessAplication: false,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setPhotoLink: (state, action) => {
			state.photo_link = action.payload
		},
		setAccessToken: (state, action) => {
			state.access_token = 'Bearer ' + action.payload
		},
		setLoginProvider: (state, action) => {
			state.authLoginProvider = action.payload
		},
		setFbABTest: (state, action) => {
			state.fbABTest = action.payload
		},
	},
	extraReducers: {
		[loginUserThunk.fulfilled]: (state, action) => {
			const { access_token } = action.payload
			ampEventWithEventProperty(
				AnalyticsEventType.SIGN_IN_SUCCESS,
				AnalyticsParams.signInSuccess('email'),
			)

			state.access_token = access_token
		},
		[createUserThunk.fulfilled]: () => {
			ampEventWithEventProperty(
				AnalyticsEventType.ACCOUNT_CREATED,
				AnalyticsParams.accountCreated('email'),
			)
			FacebookPixel.track('CompleteRegistration')
			LinkedInTag.track(REACT_APP_LINKEDIN_REGISTRATION_CONVERSION_ID)
		},
		[getUserInfoThunk.fulfilled]: (state, action) => {
			if (!window.db_userId) window.db_userId = action.payload.user_id
			if (!window.userEmail) window.userEmail = action.payload.email
			localStorage.setItem('good-episode_user_id', action.payload.user_id)

			return { ...state, ...action.payload }
		},
		[updateUserThunk.fulfilled]: (state, action) => {
			const { gender, description } = action.payload
			if (state.gender !== gender) {
				ampSetUserProperty('gender', gender)
			}
			if (state.description !== description) {
				ampSetUserProperty('description', description)
			}
			return { ...state, ...action.payload }
		},
		[updateUserImageThunk.fulfilled]: (state, action) => {
			const { link } = action.payload
			ampEvent(AnalyticsEventType.PROFILE_PICTURE_UPLOAD_SUCCESS)

			return { ...state, photo_link: link }
		},
		[updateUserImageThunk.rejected]: (_, action) => {
			ampEventWithEventProperty(
				AnalyticsEventType.PROFILE_PICTURE_UPLOAD_FAIL,
				AnalyticsParams.profilePictureUploadFail(action.error.message),
			)
		},
		[publishNowChapterThunk.fulfilled]: state => {
			if (!state.isSubmitSuccessAplication) {
				FacebookPixel.track('SubmitApplication')
				state.isSubmitSuccessAplication = true
			}
		},
		[logoutUserThunk.fulfilled]: () => {
			return initialState
		},
	},
})

export const { setPhotoLink, setFbABTest, setAccessToken, setLoginProvider } =
	userSlice.actions

export const userReducer = userSlice.reducer
