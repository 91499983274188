import { buttonStyles } from './button'
import { formLabelStyles } from './formLabel'
import { headingStyle } from './heading'
import { inputStyles } from './input'
import { menuStyles } from './menu'
import { selectStyles } from './select'
import { textStyle } from './text'
import { modalStyle } from './modal'
import { tooltipStyles } from './tooltip'

export const components = {
	Button: buttonStyles,
	Tooltip: tooltipStyles,
	Menu: menuStyles,
	Text: textStyle,
	Heading: headingStyle,
	Input: inputStyles,
	Select: selectStyles,
	FormLabel: formLabelStyles,
	Modal: modalStyle,
}
