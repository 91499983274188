import { colors } from '../colors'

export const formLabelStyles = {
	baseStyle: {
		fontWeight: '600',
		fontSize: '12px',
		lineHeight: '110%',
		color: '#5d5e60',
	},
	variants: {
		default: {
			mb: '2px',
		},
		name: {
			mb: 0,
		},
		date: {
			mb: '10px',
		},
		profileImage: {
			cursor: 'pointer',
			background: 'transparent',
			display: 'flex',
			alignItems: 'center',
			fontWeight: 'bold',
			fontSize: '20px',
			lineHeight: '110%',
			color: '#d0006e',
			m: 0,
		},
		profileField: {
			mb: '15px',
			fontWeight: 'bold',
			fontSize: '15px',
			color: colors['main-dark'],
		},
		profileRadio: {
			m: '0 30px 0 0',
			fontWeight: '600',
			fontSize: '14px',
			color: colors['main-dark'],
		},
		bookTitleField: {
			fontWeight: '800',
			fontSize: '20px',
			lineHeight: '22px',
			color: colors['main-dark'],
			letterSpacing: '-0.408px',
		},
		adminEditorField: {
			fontWeight: '800',
			fontSize: '14px',
			lineHeight: '14px',
			color: colors['main-dark'],
			display: 'flex',
			gap: '10px',
			alignItems: 'center',
			mb: '2px',
		},
		file: {
			padding: '10.5px 21px',
			gap: '10px',
			display: 'flex',
			alignItems: 'center',
			fontWeight: 'bold',
			fontSize: '14px',
			lineHeight: '100%',
			borderRadius: '30px',
			color: colors.white,
			bg: colors.pink100,
			cursor: 'pointer',
			userSelect: 'none',
			m: '0',
			transition: 'background 0.15s',

			'&:hover': {
				bg: '#c3086c',
			},
		},
	},
	defaultProps: {
		variant: 'default',
	},
}
