import { AuthStatesTypes } from '../../models'
import LoginEmailForm from '../LoginEmailForm'
import LoginPasswordForm from '../LoginPasswordForm'
import React from 'react'
import SignUpForm from '../SignUpForm'

const AuthForm = ({
	authState,
	isValidatedEmail,
	resetPasswordEmail,
	isShowLoading,
	onSubmitLoginEmail,
	onSubmitLoginPassword,
	passwordLoginValidationSchema,
	loginViaGoogleHandler,
	loginViaFacebookHandler,
	loginEmail,
	signInAnotherAccountHandler,
	onSubmitCreateAccountForm,
	loginButtonHandler,
	signUpLinkHandler,
	handleResetPassword,
	fbABTest,
}) => {
	switch (authState) {
		case AuthStatesTypes.SIGN_IN: {
			return !isValidatedEmail ? (
				<LoginEmailForm
					isShowLoading={isShowLoading}
					resetPasswordEmail={resetPasswordEmail}
					onSubmitLoginEmail={onSubmitLoginEmail}
					loginViaGoogleHandler={loginViaGoogleHandler}
					loginViaFacebookHandler={loginViaFacebookHandler}
					signUpLinkHandler={signUpLinkHandler}
					fbABTest={fbABTest}
				/>
			) : (
				<LoginPasswordForm
					isShowLoading={isShowLoading}
					loginEmail={loginEmail}
					passwordLoginValidationSchema={passwordLoginValidationSchema}
					onSubmitLoginPassword={onSubmitLoginPassword}
					signInAnotherAccountHandler={signInAnotherAccountHandler}
					loginButtonHandler={loginButtonHandler}
					handleResetPassword={handleResetPassword}
				/>
			)
		}
		case AuthStatesTypes.SIGN_UP: {
			return (
				<SignUpForm
					onSubmitCreateAccountForm={onSubmitCreateAccountForm}
					isShowLoading={isShowLoading}
				/>
			)
		}
		case AuthStatesTypes.RESET_PASSWORD:
		default: {
			return <></>
		}
	}
}

export default AuthForm
