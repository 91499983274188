import { createSlice } from '@reduxjs/toolkit'
import { logoutUserThunk } from '../user/asyncThunks'
import {
	fetchBookLanguagesThunk,
	fetchBooksGenresThunk,
	fetchBooksTagsThunk,
} from './asyncThunks'

export const STATE_KEY = 'types'

const initialState = {
	bookTags: [],
	bookGenres: [],
	languages: [],
}

export const typesSlice = createSlice({
	name: 'types',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchBooksTagsThunk.fulfilled]: (state, action) => {
			state.bookTags = action.payload
		},
		[fetchBooksGenresThunk.fulfilled]: (state, action) => {
			state.bookGenres = action.payload
		},
		[fetchBookLanguagesThunk.fulfilled]: (state, action) => {
			state.languages = action.payload
		},

		[logoutUserThunk.fulfilled]: () => {
			return initialState
		},
	},
})

export const typesReducer = typesSlice.reducer
