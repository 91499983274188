import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import localization from '../../../utils/localization'

function WithLocalization({ children }) {
	const { i18n } = useTranslation()

	useEffect(() => {
		i18n.changeLanguage(localization.language)
	}, [i18n])

	return children
}

export { WithLocalization }
