import { Navigate, useRoutes } from 'react-router'
import React, { lazy } from 'react'
import {
	getIsCommonUser,
	getRedirectRouteByRole,
} from '../../redux/slices/user/selectors'

import { useSelector } from 'react-redux'

const HomePage = lazy(() => import('pages/Home'))
const BookReader = lazy(() => import('pages/BookReader'))
const ChapterEditor = lazy(() => import('pages/ChapterEditor'))
const CreateBookPage = lazy(() => import('pages/CreateBook'))
const EditUserPage = lazy(() => import('pages/EditUser'))
const BookEditor = lazy(() => import('pages/BookEditor'))
const BookPage = lazy(() => import('pages/Book'))
const BookDashboardPage = lazy(() => import('pages/book-dashboard'))

const WriterRoutes = () => {
	const routes = useRoutes([
		{
			path: '*',
			element: <Navigate to='/writer/profile' />,
		},
		{
			path: 'profile',
			children: [
				{
					path: 'edit',
					element: <EditUserPage />,
				},
				{ index: true, element: <HomePage /> },
			],
		},
		{
			path: 'book',
			children: [
				{
					path: ':book_id',
					children: [
						{
							path: 'statistics',
							element: <BookDashboardPage />,
						},
						{
							path: 'edit',
							element: <BookEditor />,
						},
						{
							path: 'read/:chapter_id',
							element: <BookReader />,
						},
						{
							path: 'write/:chapter_id',
							element: <ChapterEditor />,
						},
						{ index: true, element: <BookPage /> },
					],
				},
				{
					path: 'create',
					element: <CreateBookPage />,
				},
			],
		},
	])

	const isUserAdminOrWriter = useSelector(getIsCommonUser)
	const redirectRoute = useSelector(getRedirectRouteByRole)

	return isUserAdminOrWriter ? routes : <Navigate to={redirectRoute} />
}

export default WriterRoutes
