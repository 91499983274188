import axiosApiInstance from 'utils/axios-api-instance'

export const createUser = body => axiosApiInstance.post(`api/user`, body)

export const getUserInfo = () => axiosApiInstance.get(`api/user`)

export const getIsAlreadyExistEmail = async email => {
	const { valid } = await axiosApiInstance
		.get(`api/user/${email}`)
		.then(res => res.data)

	return valid
}

export const loginUser = body => axiosApiInstance.post(`api/user/login`, body)

export const updateUser = body => axiosApiInstance.put(`api/user`, body)

export const updateUserImage = formdata =>
	axiosApiInstance.post(`api/file/upload/user`, formdata)
