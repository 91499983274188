import { makeVar } from '@apollo/client'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

export const chaptersText = makeVar({})

export const updateChapterText = (chapterId, text) => {
	const currentValue = { ...chaptersText() }

	currentValue[chapterId] = text

	chaptersText(currentValue)
}

export const fetchChapterText = async (chapterId, link) => {
	const currentText = readChapterText(chapterId)

	if (currentText || !chapterId || !link) {
		console.log(`skipping ${link}`)
		return currentText
	}

	const result = await axios.get(`${link}?caching=${uuidv4()}`)
	const text = result.data

	updateChapterText(chapterId, text)

	return text
}

export const readChapterText = chapterId => {
	const text = chaptersText()[chapterId]
	return text || ''
}

export const clearChaptersText = () => {
	chaptersText({})
}
