import { STATE_KEY as BOOKS_STATE_KEY, booksReducer } from './slices/books'
import {
	STATE_KEY as CHAPTERS_STATE_KEY,
	chaptersReducer,
} from './slices/chapters'
import { STATE_KEY as TYPES_STATE_KEY, typesReducer } from './slices/types'
import { STATE_KEY as UI_STATE_KEY, uiReducer } from './slices/ui'
import { STATE_KEY as USER_STATE_KEY, userReducer } from './slices/user'

import {
	STATE_KEY as REVENUE_STATE_KEY,
	revenueReducer,
} from './slices/revenue'

import { combineReducers } from 'redux'

const rootReducer = combineReducers({
	[USER_STATE_KEY]: userReducer,
	[BOOKS_STATE_KEY]: booksReducer,
	[TYPES_STATE_KEY]: typesReducer,
	[UI_STATE_KEY]: uiReducer,
	[CHAPTERS_STATE_KEY]: chaptersReducer,
	[REVENUE_STATE_KEY]: revenueReducer,
})

export default rootReducer
