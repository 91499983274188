import axiosApiInstance from 'utils/axios-api-instance'

export const updateChapterStatusByModerator = body =>
	axiosApiInstance.put(`api/chapters/moderator`, body)

export const updateChapterStatusByWriter = body =>
	axiosApiInstance.put(`api/chapters/writer`, body)

export const createChapter = body => axiosApiInstance.post(`api/chapters`, body)

export const updateChapter = body => axiosApiInstance.put(`api/chapters`, body)

export const fetchDetailedChapter = chapter_id =>
	axiosApiInstance.get(`api/chapters/detailed/${chapter_id}`)

export const fetchBookChapters = book_id =>
	axiosApiInstance.get(`api/chapters/${book_id}`)

export const updateProtectedFieldsChapter = body =>
	axiosApiInstance.put(`api/chapters/moderator`, body)

export const reorderBookChapters = body =>
	axiosApiInstance.put(`api/chapters/butch/order`, body)

export const uploadChapters = ({ formdata, book_id }) =>
	axiosApiInstance.post(`api/scripts/chapters/batch/${book_id}`, formdata)
