import { Navigate, useRoutes } from 'react-router'
import React, { lazy } from 'react'
import {
	getIsUserAdmin,
	getRedirectRouteByRole,
} from 'redux/slices/user/selectors'
import { useSelector } from 'react-redux'

const RevenueReports = lazy(() => import('pages/Revenue/Main/pages/Reports'))
const RevenueRequests = lazy(() => import('pages/Revenue/Main/pages/Requests'))
const RevenueAuthors = lazy(() => import('pages/Revenue/Main/pages/Authors'))
const RevenueMainPage = lazy(() => import('pages/Revenue/Main'))
const RevenueReportDetailsPage = lazy(() =>
	import('pages/Revenue/ReportDetails'),
)

const RevenueRoutes = () => {
	const routes = useRoutes([
		{
			path: '*',
			element: <Navigate to='/revenue' />,
		},
		{
			path: '/',
			element: <RevenueMainPage />,
			children: [
				{
					index: true,
					element: <RevenueReports />,
				},
				{
					path: 'requests',
					element: <RevenueRequests />,
				},
				{
					path: 'authors',
					element: <RevenueAuthors />,
				},
			],
		},
		{
			path: 'report/:report_id',
			element: <RevenueReportDetailsPage />,
		},
	])

	const isAdmin = useSelector(getIsUserAdmin)
	const redirectRoute = useSelector(getRedirectRouteByRole)

	return isAdmin ? routes : <Navigate to={redirectRoute} />
}

export default RevenueRoutes
