import { useMutation } from '@apollo/client'
import { errorToast, successToast } from 'components/DefaultToast'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { VERIFY_USER_EMAIL } from 'entites/user/verify-user-email.graphql'
import { useState, useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const REDIRECT_TIMEOUT = 3500

const VerificationPage = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [isOperated, setIsOperated] = useState(false)
	const [token, setToken] = useState('')
	const location = useLocation()
	const navigate = useNavigate()

	const [canVerifyUserEmail, { data: verificationResponce }] = useMutation(
		VERIFY_USER_EMAIL,
		{
			variables: { token },
		},
	)

	const getToken = useCallback(() => {
		const tokenFromLink = location.pathname.replace('/email/verification/', '')

		setToken(tokenFromLink)
	}, [setToken, location])

	const verifyUserEmail = useCallback(() => {
		canVerifyUserEmail()
		setIsOperated(true)
	}, [canVerifyUserEmail])

	useEffect(() => {
		if (verificationResponce && isOperated) {
			if (
				Object.prototype.hasOwnProperty.call(
					verificationResponce.verifyUserEmail,
					'error',
				)
			) {
				errorToast(verificationResponce.verifyUserEmail.error)
				setIsOperated(false)
				setIsLoading(false)
				setTimeout(() => {
					navigate('writer')
				}, REDIRECT_TIMEOUT)
			}

			if (
				Object.prototype.hasOwnProperty.call(
					verificationResponce.verifyUserEmail,
					'success',
				)
			) {
				successToast(verificationResponce.verifyUserEmail.success)
				setIsOperated(false)
				setIsLoading(false)
				setTimeout(() => {
					navigate('writer')
				}, REDIRECT_TIMEOUT)
			}
		}
	}, [isOperated, navigate, verificationResponce])

	useEffect(() => {
		getToken()
	}, [getToken])

	useEffect(() => {
		if (token) {
			verifyUserEmail()
		}
	}, [verifyUserEmail, token])

	return <>{isLoading && <LoadingSpinner />}</>
}

export default VerificationPage
