import {
	errorToast as errorToast2,
	successToast as toast,
} from 'pages/Revenue/Main/toasts'

export const successToast = message => {
	toast(message)
}

export const errorToast = message => {
	errorToast2(message)
}
