import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import {
	Box,
	Flex,
	FormLabel,
	Image,
	Input,
	ListItem,
	UnorderedList,
} from '@chakra-ui/react'

const PasswordFormControl = () => {
	const [field, meta] = useField({ name: 'password' })
	const { t } = useTranslation()
	const isInvalid = meta.touched && meta.error

	const PasswordErrors = {
		required: 'Required',
		length: 'Password should be at least 8 characters',
		symbolAndNumber:
			'Password should be at least one number, one letter and no spaces',
	}
	Object.freeze(PasswordErrors)

	const isRequired = meta.error?.includes(PasswordErrors.required)

	const minLengthError =
		isRequired || meta.error?.includes(PasswordErrors.length)

	const symbolAndNumberError =
		isRequired || meta.error?.includes(PasswordErrors.symbolAndNumber)

	return (
		<Flex pb={meta.touched ? '54px' : '37px'} pos='relative' direction='column'>
			<FormLabel
				htmlFor='password'
				mb='2px'
				fontWeight='600'
				fontSize='12px'
				lineHeight='110%'
				color='#5d5e60'
			>
				{t('Password')}
			</FormLabel>

			<Box pos='relative'>
				<Input
					id='password'
					name='password'
					type='password'
					maxLength='20'
					borderBottom={isInvalid ? '1px solid #dc6566' : '1px solid #dbdbdb'}
					{...field}
				/>
				{isInvalid && (
					<Image
						src='/images/authorization/error-icon.svg'
						alt='error-validation-icon'
						pos='absolute'
						right='0'
						bottom='3.5px'
					/>
				)}
			</Box>

			{meta.touched && (
				<UnorderedList
					pos='absolute'
					m='0'
					bottom='10px'
					paddingInlineStart='20px'
				>
					<ListItem
						mb='3px'
						fontWeight='600px'
						fontSize='12px'
						lineHeight='110%'
						color={minLengthError ? '#ea4335' : '#0b080f'}
					>
						{t(PasswordErrors.length)}
					</ListItem>

					<ListItem
						mb='3px'
						fontWeight='600px'
						fontSize='12px'
						lineHeight='110%'
						color={symbolAndNumberError ? '#ea4335' : '#0b080f'}
					>
						{t(PasswordErrors.symbolAndNumber)}
					</ListItem>
				</UnorderedList>
			)}
		</Flex>
	)
}

export default PasswordFormControl
