import * as R from 'ramda'

import { AnalyticsEventType, AnalyticsParams } from '../../../model/analytics'
import {
	createChapterThunk,
	deleteChapterThunk,
	fetchBookChaptersThunks,
	fetchChapterText,
	publishNowChapterThunk,
	reorderBookChaptersThunk,
	saveAsDraftChapterThunk,
	updateProtectedFieldsChapterThunk,
	uploadChaptersThunk,
} from './asyncThunks'

import { ampEventWithEventProperty } from '../../../utils/amplitude'
import { createSlice } from '@reduxjs/toolkit'
import { logoutUserThunk } from '../user/asyncThunks'

export const STATE_KEY = 'chapters'

const initialState = {}

export const chaptersSlice = createSlice({
	name: 'chapters',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchBookChaptersThunks.fulfilled]: (state, action) => {
			const { book_id, chapters } = action.payload
			state[book_id] = chapters.reduce(
				(acc, chapter) => ({
					...acc,
					[chapter.chapter_id]: {
						text: '',
						...state[book_id]?.[chapter.chapter_id],
						...chapter,
					},
				}),
				{},
			)
		},
		[fetchChapterText.fulfilled]: (state, action) => {
			const { book_id, chapter_id, text } = action.payload
			state[book_id][chapter_id].text = text
		},
		[createChapterThunk.fulfilled]: (state, action) => {
			const { source, chapter } = action.payload

			const { book_id, chapter_id } = chapter

			ampEventWithEventProperty(
				AnalyticsEventType.NEW_EPISODE_SUCCESS,
				AnalyticsParams.newEpisodeSuccess(source, book_id),
			)

			return R.assocPath(
				[book_id, chapter_id],
				{ chapter_id, ...chapter },
				state,
			)
		},
		[createChapterThunk.rejected]: (_, action) => {
			const { source, book_id } = action.meta.arg
			ampEventWithEventProperty(
				AnalyticsEventType.NEW_EPISODE_FAIL,
				AnalyticsParams.newEpisodeFail(source, book_id),
			)
		},
		[deleteChapterThunk.fulfilled]: (state, action) => {
			const { book_id, chapter_id, source } = action.payload
			ampEventWithEventProperty(
				AnalyticsEventType.DELETE_CHAPTER_SUCCESS,
				AnalyticsParams.delteChapterAttempted(source, book_id, chapter_id),
			)
			delete state[book_id][chapter_id]
		},
		[deleteChapterThunk.rejected]: (state, action) => {
			const { book_id, chapter_id, source } = action.meta.arg
			ampEventWithEventProperty(
				AnalyticsEventType.DELETE_CHAPTER_FAIL,
				AnalyticsParams.deleteChapterFail(source, book_id, chapter_id),
			)

			delete state[book_id][chapter_id]
		},
		[saveAsDraftChapterThunk.fulfilled]: (state, action) => {
			const {
				status,
				chapter_id,
				title,
				price,
				text,
				book_id,
				word_count_delta,
			} = action.payload

			ampEventWithEventProperty(
				AnalyticsEventType.SAVE_AS_DRAFT_SUCCESS,
				AnalyticsParams.saveAsDraftSuccess(
					book_id,
					chapter_id,
					word_count_delta,
				),
			)

			state[book_id][chapter_id].title = title
			state[book_id][chapter_id].status = status
			state[book_id][chapter_id].text = text
			state[book_id][chapter_id].price = price
		},
		[publishNowChapterThunk.fulfilled]: (state, action) => {
			const {
				status,
				chapter_id,
				text,
				title,
				price,
				book_id,
				word_count_delta,
			} = action.payload
			ampEventWithEventProperty(
				AnalyticsEventType.PUBLISH_NOW_SUCCESS,
				AnalyticsParams.publishNowSuccess(
					book_id,
					chapter_id,
					word_count_delta,
				),
			)

			state[book_id][chapter_id].title = title
			state[book_id][chapter_id].status = status
			state[book_id][chapter_id].text = text
			state[book_id][chapter_id].price = price
		},
		[updateProtectedFieldsChapterThunk.fulfilled]: (state, action) => {
			const { book_id, chapter_id, ...restInfo } = action.payload
			state[book_id][chapter_id] = {
				...state[book_id][chapter_id],
				...restInfo,
			}
		},
		[logoutUserThunk.fulfilled]: () => {
			return initialState
		},
		[reorderBookChaptersThunk.fulfilled]: (state, action) => {
			const { book_id, chapters } = action.payload
			state[book_id] = chapters.reduce(
				(acc, chapter) => ({
					...acc,
					[chapter.chapter_id]: {
						...state[book_id]?.[chapter.chapter_id],
						...chapter,
					},
				}),
				{},
			)
		},
		[uploadChaptersThunk.fulfilled]: (state, action) => {
			const { book_id, chapters } = action.payload
			state[book_id] = chapters.reduce(
				(acc, chapter) => ({
					...acc,
					[chapter.chapter_id]: {
						text: '',
						...state[book_id]?.[chapter.chapter_id],
						...chapter,
					},
				}),
				state[book_id] || {},
			)
		},
	},
})

export const { createNewChapterLocally } = chaptersSlice.actions

export const chaptersReducer = chaptersSlice.reducer
