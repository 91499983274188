import { UserRoles } from '../../../../constants/user'
import { createSelector } from 'reselect'

const getState = state => state.user

export const getEmail = createSelector(getState, state => state.email)

export const getIsSendResetPasswordEmail = createSelector(
	getState,
	state => state.isSendResetPasswordEmail,
)

export const getUsername = createSelector(
	getState,
	state => state.first_name + ' ' + state.last_name,
)

export const getFirstName = createSelector(getState, state => state.first_name)

export const getLastName = createSelector(getState, state => state.last_name)

export const getPhotoLink = createSelector(getState, state => state.photo_link)

export const getUserDescription = createSelector(
	getState,
	state => state.description,
)

export const getUserGender = createSelector(getState, state => state.gender)

export const getUserId = createSelector(getState, state => state.user_id)

export const getAuthLoginProvider = createSelector(
	getState,
	state => state.authLoginProvider,
)

export const getUserEmailStatus = createSelector(
	getState,
	state => state.status,
)

export const getAccessToken = createSelector(
	getState,
	state => state.access_token,
)

export const getUserRole = createSelector(getState, state => state.role)

export const getIsUserAdmin = createSelector(
	getUserRole,
	getAccessToken,
	(role, access_token) => access_token && role === UserRoles.ADMIN,
)

export const getIsUserSourcer = createSelector(
	getUserRole,
	getAccessToken,
	(role, access_token) => access_token && role === UserRoles.SOURCER,
)

export const getIsUserWriter = createSelector(
	getUserRole,
	getAccessToken,
	(role, access_token) => access_token && role === UserRoles.WRITER,
)

export const getIsUserModerator = createSelector(
	getUserRole,
	getAccessToken,
	(role, access_token) => access_token && role === UserRoles.MODERATOR,
)

export const getIsCommonUser = createSelector(
	getUserRole,
	getAccessToken,
	(role, access_token) =>
		access_token &&
		(role === UserRoles.ADMIN ||
			role === UserRoles.WRITER ||
			role === UserRoles.SOURCER),
)

export const getRedirectRouteByRole = createSelector(
	getUserRole,
	getAccessToken,
	(role, access_token) => {
		if (!access_token) return '/auth/sign-in'

		if (
			role === UserRoles.WRITER ||
			role === UserRoles.ADMIN ||
			role === UserRoles.SOURCER
		)
			return '/writer/profile'

		if (role === UserRoles.MODERATOR) return '/moderator/dashboard'
	},
)

export const getFbABTest = createSelector(getState, state => state.fbABTest)
