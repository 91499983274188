import { AnalyticsEventType, AnalyticsParams } from '../../model/analytics'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ampEventWithEventProperty } from '../../utils/amplitude'
import { getAuthLoginProvider } from '../../redux/slices/user/selectors'
import { getUserInfoThunk } from '../../redux/slices/user/asyncThunks'
import { setAccessToken } from '../../redux/slices/user'
import { useNavigate } from 'react-router-dom'
import useQuery from '../../hooks/useQuery'

const Authenticate = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { access_token, type } = useQuery()

	const authLoginProvider = useSelector(getAuthLoginProvider)

	const handleUserInfoGet = useCallback(async () => {
		try {
			if (access_token) {
				await dispatch(setAccessToken(access_token))
				await dispatch(getUserInfoThunk())
				ampEventWithEventProperty(
					AnalyticsEventType.SIGN_IN_SUCCESS,
					AnalyticsParams.signInSuccess(authLoginProvider),
				)
				navigate('/profile', { state: { type } })
				return
			}
			throw new Error()
		} catch (error) {
			navigate('/auth/sign-in')
		}
	}, [access_token, authLoginProvider, dispatch, navigate, type])

	useEffect(() => {
		handleUserInfoGet()
	}, [handleUserInfoGet])

	return <></>
}

export default Authenticate
