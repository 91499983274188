import {
	fetchBookLanguages,
	fetchBooksGenres,
	fetchBooksTags,
} from '../../../../api/types'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchBooksTagsThunk = createAsyncThunk(
	'types/fetchBooksTags',
	async () => {
		try {
			const res = await fetchBooksTags()
			return res.data
		} catch (error) {
			console.error(error)
			throw error
		}
	},
)

export const fetchBooksGenresThunk = createAsyncThunk(
	'types/fetchBooksGenres',
	async () => {
		try {
			const res = await fetchBooksGenres()
			return res.data
		} catch (error) {
			console.error(error)
			throw error
		}
	},
)

export const fetchBookLanguagesThunk = createAsyncThunk(
	'types/fetchBookLanguages',
	async () => {
		try {
			const res = await fetchBookLanguages()
			return res.data
		} catch (error) {
			console.error(error)
			throw error
		}
	},
)
