import { gql } from '@apollo/client'

export const CREATE_USER = gql`
	mutation CreateUser($user: CreateUserDto!) {
		createUser(user: $user) {
			user_id
			email
			first_name
			last_name
		}
	}
`
