import { gql } from '@apollo/client'

export const VERIFY_USER_EMAIL = gql`
	mutation VerifyUserEmail($token: String!) {
		verifyUserEmail(token: $token) {
			error
			success
		}
	}
`
