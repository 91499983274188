import { keyframes } from '@chakra-ui/react'

const appear = keyframes({
	from: { opacity: 0 },
	to: { opacity: 1 },
})

export const modalStyle = {
	baseStyle: {
		dialog: {
			height: '376px',
			padding: '32px 28px',
			opacity: 1,
			borderRadius: '20px',
			animation: `${appear} 0.2s linear`,
		},
		body: {
			fontFamily: 'Nunito Sans',
			fontSize: '16px',
			lineHeight: '22px',
			padding: '0',
		},
		header: {
			fontFamily: 'Nunito Sans',
			fontWeight: 800,
			fontSize: '28px',
			lineHeight: '36px',
			margin: '0 0 16px',
			padding: '0',
		},
		footer: {
			display: 'flex',
			gap: '8px',
			margin: '0',
			padding: '0',
		},
	},
	variants: {
		contentGuidline: {
			dialog: {
				height: '512px',
				w: '844px',
				maxW: '844px',
				padding: '0',
				opacity: 1,
				borderRadius: '20px',
				animation: `${appear} 0.2s linear`,
			},
			body: {
				fontFamily: 'Nunito Sans',
				fontSize: '16px',
				lineHeight: '22px',
				padding: '0',

				'&::-webkit-scrollbar': {
					w: '11px',
				},
				'&::-webkit-scrollbar-track': {
					mt: '5px',
					mb: '5px',
					borderTopRightRadius: '200px',
					borderBottomRightRadius: '200px',
					borderLeft: '1px solid #E8E8E8',
					bg: '#FCFCFC',
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: '25px',
					p: '2px',
					borderRight: '2px solid transparent',
					borderLeft: '3px solid transparent',
					borderTop: '5px solid transparent',
					borderBottom: '5px solid transparent',
					bg: `#7A7A7A`,
					backgroundClip: 'content-box',
				},
			},
		},
	},
}
