import './styles/styles.scss'
import './utils/localization'

import { persistor, store } from './redux/store'

import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import WithApollo from 'components/hoc/WithApollo'
import { WithLocalization } from './components/hoc/LocalizationProvider'
import { ChakraProvider } from '@chakra-ui/react'
import chakraTheme from 'shared/utils/chakra-theme'

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<WithLocalization>
						<ChakraProvider theme={chakraTheme}>
							<WithApollo>
								<App />
							</WithApollo>
						</ChakraProvider>
					</WithLocalization>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
)
