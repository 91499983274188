import { createSlice } from '@reduxjs/toolkit'
import {
	createUserThunk,
	loginUserThunk,
	logoutUserThunk,
	updateUserThunk,
} from '../user/asyncThunks'
import {
	publishNowChapterThunk,
	saveAsDraftChapterThunk,
} from '../chapters/asyncThunks'
import { AlertMessages, AlertStates } from '../../../constants/chapters'
import { UserRoles } from '../../../constants/user'

export const STATE_KEY = 'ui'

const initialState = {
	isShowLoading: false,
	alert: {
		message: '',
		status: '',
		isShow: false,
	},
}

export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		hideAlert: state => {
			state.alert = {
				message: '',
				status: '',
				isShow: false,
			}
		},
	},
	extraReducers: {
		[createUserThunk.pending]: state => {
			state.isShowLoading = true
		},
		[createUserThunk.fulfilled]: state => {
			state.isShowLoading = false
		},
		[createUserThunk.rejected]: state => {
			state.isShowLoading = false
		},
		[loginUserThunk.pending]: state => {
			state.isShowLoading = true
		},
		[loginUserThunk.fulfilled]: state => {
			state.isShowLoading = false
		},
		[loginUserThunk.rejected]: state => {
			state.isShowLoading = false
		},
		[updateUserThunk.rejected]: state => {
			state.isUpdatedUserError = false
		},
		[logoutUserThunk.fulfilled]: () => {
			return initialState
		},
		[publishNowChapterThunk.fulfilled]: (state, action) => {
			const { userRole } = action.meta.arg
			const alert = {
				status: AlertStates.SUCCESS,
				isShow: true,
				message:
					userRole === UserRoles.WRITER
						? AlertMessages.SUCCESS_TO_MODERATED
						: AlertMessages.SUCCESS_PUBLISHED,
			}
			state.alert = alert
		},
		[publishNowChapterThunk.rejected]: (state, action) => {
			const { userRole } = action.meta.arg
			const alert = {
				status: AlertStates.ERROR,
				isShow: true,
				message:
					userRole === UserRoles.WRITER
						? AlertMessages.ERROR_TO_MODERATED
						: AlertMessages.ERROR_PUBLISHED,
			}
			state.alert = alert
		},
		[saveAsDraftChapterThunk.fulfilled]: state => {
			const alert = {
				status: AlertStates.SUCCESS,
				isShow: true,
				message: AlertMessages.SUCCESS_DRAFT,
			}
			state.alert = alert
		},
		[saveAsDraftChapterThunk.rejected]: state => {
			const alert = {
				status: AlertStates.ERROR,
				isShow: true,
				message: AlertMessages.ERROR_DRAFT,
			}
			state.alert = alert
		},
	},
})

export const { hideAlert } = uiSlice.actions

export const uiReducer = uiSlice.reducer
