export const colors = {
	white: '#ffffff',
	'main-dark': '#0b080f',
	blue: '#0052dd',
	red: '#dc6566',
	'dropdown-bg': '#fdfdfd',
	'light-grey': '#f8f8f8',
	'dark-tone': '#242533',
	'main-gray': '#f9f9f9',
	pink100: '#D0006E',
	'light-pink': '#fff3fa',
	gray: '#484964',
	gray4b: '#4b4b4b',
	black: '#000',
	options: '#5e5e5e',
	error: '#ea4335',
	inactive: '#b7b9bc',
}
