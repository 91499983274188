import { Navigate, Outlet, useParams, useRoutes } from 'react-router-dom'
import React, { lazy } from 'react'
import {
	getIsUserModerator,
	getRedirectRouteByRole,
} from '../../redux/slices/user/selectors'

import { getIsExistBookById } from 'redux/slices/books/selectors'
import { useSelector } from 'react-redux'

const ModeratorDashboard = lazy(() => import('pages/Moderator/Dashboard'))
const ModeratorBook = lazy(() => import('pages/Moderator/ModeratorBook'))
const NotFoundPage = lazy(() => import('pages/NotFound'))

const Moderator = () => {
	const isUserModerator = useSelector(getIsUserModerator)
	const redirectRoute = useSelector(getRedirectRouteByRole)

	return isUserModerator ? <Outlet /> : <Navigate to={redirectRoute} />
}

const ModeratorBookWrapper = () => {
	const { book_id } = useParams()
	const isExistBook = useSelector(state => getIsExistBookById(state, book_id))
	return isExistBook ? <ModeratorBook /> : <NotFoundPage />
}

const ModeratorRoutes = () => {
	const routes = useRoutes([
		{
			path: '/',
			element: <Moderator />,
			children: [
				{
					path: 'dashboard',
					element: <ModeratorDashboard />,
				},
				{
					path: 'book',
					children: [
						{ path: ':book_id', element: <ModeratorBookWrapper /> },
						{ index: true, element: <NotFoundPage /> },
					],
				},
			],
		},
		{
			path: '*',
			element: <NotFoundPage />,
		},
	])

	return routes
}

export default ModeratorRoutes
