import { createSelector } from '@reduxjs/toolkit'

export const getBooks = state => state.books

export const getBooksList = createSelector(getBooks, books =>
	Object.values(books).filter(book => !book.deleted),
)

export const getBookCount = createSelector(getBooksList, books => books.length)

export const getBookById = createSelector(
	getBooks,
	(_, book_id) => book_id,
	(books, book_id) => books[book_id] || {},
)

export const getIsExistBookById = createSelector(
	getBookById,
	book => Object.keys(book).length > 0,
)
